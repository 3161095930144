import React, { Component } from 'react';
import { Form, Button, Modal, Row, Col, Spinner, Card} from 'react-bootstrap';
import { connect } from 'react-redux';
import '../App.css';
import * as api from '../api/api';
import * as actions from '../actions';
import UserConfirmation from './UserConfirmation';
import ReactGA from 'react-ga';
import centalogo from '../assets/images/logo.png';
import Iframe from 'react-iframe'
import InstallationPopup from './InstallationPopup'
import GoogleOneTapSignin from './onetapsignin/GoogleOneTapSignin'
import ReCAPTCHA from 'react-google-recaptcha';

class SignUp extends Component {
  constructor(props){
    super(props);
    this.state={
      username: '',
      password: '',
      phone: '',
      confirmPassword:'',
      errorMsg: '',
      code: 91,
      email: '',
      buttonDisabled: false,
      showLoader: false,
      referralCode: '',
      exceptionError: '',
      showUserConfComponent: '',
      showModal: false,
      showError: false,
      iframeURL: '',
      formErrors: {
        invalidPhoneNumber: '',
        mandatoryCheck: '',
        pwdNotMatches: '',
        invalidEmail: '',
      },
      showCaptchaPopUp: false
    }
  }
  componentDidMount() {
    ReactGA.initialize('G-9KWCK6VCXB');
    ReactGA.pageview('pwa/signup');
    let urlParams = this.props.location.search;
    let code
    if (urlParams !== '') {
      code = urlParams.split('=')
      this.setState({referralCode: code[1]})
    }
    if(this.props.user && this.props.user.accessToken) {
      this.props.history.push('/pwa/home');
    }
  }

  validateForm = () => {
    let error = true
    let errorObj = Object.assign({}, this.state.formErrors)
    let phone = this.state.phone
    var phoneno = /^\d{5,20}$/;

    errorObj.invalidPhoneNumber= ''
    errorObj.mandatoryCheck= ''
    errorObj.pwdNotMatches= ''
    errorObj.invalidEmail= ''
    this.setState({errorMsg: ''})

    if ((this.state.username === '') || (this.state.code === '') || (this.state.phone === '') || (this.state.password === '') || (this.state.confirmPassword === '') || (this.state.email === undefined || this.state.email === '')) {
      errorObj.mandatoryCheck= 'Please fill all the details to Sign Up'
      error = false
    }
    else {
      if ((this.state.code === '91') || (this.state.code === 91)){
        if(phone.length !== 10){
          errorObj.invalidPhoneNumber='Please enter a valid 10 digit phone number.'
          error = false
        }
      }

      if(!phone.match(phoneno)) {
        errorObj.invalidPhoneNumber='Please enter a valid phone number.'
        error = false
      }

      if((this.state.password !== this.state.confirmPassword)){
        errorObj.pwdNotMatches='Password and Confirm Password does not matches'
        error = false
      }

      var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-zA-Z0-9]+(\-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(\-[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,4}$/;
      if(!pattern.test(this.state.email)){
        errorObj.invalidEmail='Invalid Email Format'
        error = false
      }
    }
    this.setState({formErrors: errorObj, buttonDisabled:false, showLoader:false, showError: true})
    return error
  }

  SignUp = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  onPhoneNumChange = event => {
    event.preventDefault();
    this.setState({[event.target.id]: event.target.value})
  }

  handleSignUp =(e) => {
    e.preventDefault();
    this.setState({buttonDisabled:true,showLoader:true})
    const obj = {}
    if(this.validateForm()) {
      ReactGA.initialize('G-9KWCK6VCXB');
      ReactGA.event({
        category: 'Signup',
        action: 'Click/signup',
        label: 'user/signup'
      });
      let SignUpinfo ={}
      SignUpinfo.user_name=this.state.username
      SignUpinfo.country_code = this.state.code
      SignUpinfo.phone= this.state.code + this.state.phone
      SignUpinfo.password=this.state.password
      SignUpinfo.password_confirmation=this.state.confirmPassword
      if(this.state.referralCode === '') {
        SignUpinfo.used_referral_code = null
      }
      else {
        SignUpinfo.used_referral_code = this.state.referralCode
      }
      SignUpinfo.email = this.state.email
      api.signUp(SignUpinfo)
      .then((response) => {
        if(response.status === "success") {
          if(response.data.active){
            obj.accessToken = response.headers.accessToken            
            obj.userName = response.data.user_name
            obj.role = response.data.role
            obj.id = response.data.id
            obj.otherRoles = response.data.other_roles 
            this.props.userLogin(obj)
          }
          else {
            this.setState({showUserConfComponent: true})
          }
        }
        else{
          let errorMsg = response.data
          this.setState({errorMsg: errorMsg, buttonDisabled:false, showLoader:false, showError: true})
        }
      })
     .catch(error => this.setState({exceptionError: 'Error in Signin,Check your internet or Please Contact the Admin', buttonDisabled:false, showLoader:false, showError: true}));
    }
  }

  redirectPostLogin = (pwdChangeReqd) => {
    this.props.history.push('/pwa/home')
  }

  handleIframeforTerms = (iframeURL) => {
    this.setState({showModal: true, iframeURL: iframeURL})
  }

  handleClose = () => {
    this.setState({showModal: false})
  }

  handleOneTap = () => {
    window.location.reload(true)
  }
  
  hideCaptchaPopUp = () => {
    this.setState({ showCaptchaPopUp: false });
  }

  handleCaptchaVerify = (e) => {
    e.preventDefault();
    this.setState({showCaptchaPopUp: true });
  }

  handleOnChange = (value) => {
    if (value != null) {
      this.setState({showCaptchaPopUp: false });
      this.setState({ reCaptchaToken: value });
      this.setState({buttonDisabled:true,showLoader:true})
      let userName = {}
      userName.recaptcha_token=this.state.recaptcha_token
      userName.user_name = this.state.username
    const obj = {}
    if(this.validateForm()) {
      ReactGA.initialize('G-9KWCK6VCXB');
      ReactGA.event({
        category: 'Signup',
        action: 'Click/signup',
        label: 'user/signup'
      });
      let SignUpinfo ={}
      SignUpinfo.user_name=this.state.username
      SignUpinfo.country_code = this.state.code
      SignUpinfo.phone= this.state.code + this.state.phone
      SignUpinfo.password=this.state.password
      SignUpinfo.password_confirmation=this.state.confirmPassword
      if(this.state.referralCode === '') {
        SignUpinfo.used_referral_code = null
      }
      else {
        SignUpinfo.used_referral_code = this.state.referralCode
      }
      SignUpinfo.email = this.state.email
      api.signUp(SignUpinfo)
      .then((response) => {
        if(response.status === "success") {
          if(response.data.active){
            obj.accessToken = response.headers.accessToken            
            obj.userName = response.data.user_name
            obj.role = response.data.role
            obj.id = response.data.id
            obj.otherRoles = response.data.other_roles 
            this.props.userLogin(obj)
          }
          else {
            this.setState({showUserConfComponent: true})
          }
        }
        else{
          let errorMsg = response.data
          this.setState({errorMsg: errorMsg, buttonDisabled:false, showLoader:false, showError: true})
        }
      })
     .catch(error => this.setState({exceptionError: 'Error in Signin,Check your internet or Please Contact the Admin', buttonDisabled:false, showLoader:false, showError: true}));
      //this.handleSignUp();
    }
  }
  }

  render() {
    let errorMsg = this.state.errorMsg
    let errorComponent, displayComponent, captchaPopUp
    let handleIframeforTerms = this.handleIframeforTerms

    captchaPopUp = (<Modal  show={this.state.showCaptchaPopUp} onHide={this.hideCaptchaPopUp} backdrop='static' keyboard={false} centered>
    <Modal.Header><h3>Are you a human?</h3></Modal.Header>
    <Modal.Body>
      <div align="center">
        <ReCAPTCHA sitekey='6Lf2ZRwiAAAAAAo8b9ftV_6eeqIAXDIPkKzAk5it'
          onChange={this.handleOnChange}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'right' }} className='mt-2'>
        <Button onClick={this.hideCaptchaPopUp} className="hp-form-cancel">
          Cancel
        </Button>
      </div>
    </Modal.Body>
  </Modal>)

    let IframeModal
    if(this.state.showModal) {
      IframeModal = (
        <Modal show={this.state.showModal} onHide={this.handleClose}  size="lg" centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body align="center">
            <div>
              <Iframe url={this.state.iframeURL}
                position="relative"
                width="100%"
                id="myId"
                className="myClassname"
                height="1250px"
                allowFullScreen
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }

    if (errorMsg === ''){
    }
    else {
      errorComponent = (
        <div>
        {this.state.errorMsg.map(function(error){
          return (
            <p className ="alertTextRedesign mb-0 mt-2">{error}</p>
          )
        })}
        </div>
      )
    }

    if(this.state.showUserConfComponent){
      displayComponent = (
        <UserConfirmation  userName={this.state.username} onRedirectPostLogin={this.redirectPostLogin}/>
      )
    }
    else {
      displayComponent = (
        <Form>
          <h5 className="heading-txt mb-4" style={{fontSize: "14px"}}>HELLO TEACHER!</h5>
          <Col xs="5" sm="4" md="4" lg="4" className='float-wrapper last'>
           <Form.Label className='float-label text-muted signup-country-code'>Country Code</Form.Label>
            <input
              id="code"
              className='float-input'
              type="number" 
              onChange={this.onPhoneNumChange}
              value={this.state.code}
              />
          </Col>
          <Col xs="7" sm="8" md="8" lg="8" className="float-wrapper last">
           <Form.Label className='float-label'>Phone Number</Form.Label>
            <input
              className='float-input'
              id="phone"
              type="number"
              value={this.state.phone}
              onChange={this.onPhoneNumChange}
            />
          </Col>
          <Form.Group controlId="email" className='float-wrapper last mb-0 pb-0'>
           <Form.Label className='float-label'>Email </Form.Label>
           <Form.Control className='float-input' value={this.state.email} onChange={this.SignUp} />
          </Form.Group>
          <Form.Group controlId="username" className='float-wrapper last mb-0 pb-0'>
            <Form.Label className='float-label'>Username</Form.Label>
            <Form.Control value={this.state.username} className='float-input' onChange={this.SignUp} />
          </Form.Group>
          <Form.Group controlId="password" className='float-wrapper last mb-0 pb-0'>
            <Form.Label className='float-label'>Password</Form.Label>
            <Form.Control type="password" className='float-input' value={this.state.password} onChange={this.SignUp} />
          </Form.Group>
          <Form.Group controlId="confirmPassword" className='float-wrapper last mb-0 pb-0'>
            <Form.Label className='float-label'>Confirm Password</Form.Label>
            <Form.Control type="password" className='float-input' value={this.state.confirmPassword} onChange={this.SignUp} />
          </Form.Group>
          <br/>
          <Form.Group controlId="referralCode" className='float-wrapper last mb-0 pb-0'>
            <Form.Label className='float-label'> Referral Code <span className="text-muted">(Optional)</span></Form.Label>
            <Form.Control type="text" className='float-input' value={this.state.referralCode} onChange={this.SignUp} />
          </Form.Group>
          <dd className="default-font mt-4">By continuing, you agree to MyCENTA's <span className="privacy-policy-btn" onClick={() => handleIframeforTerms("https://centa.org/mycenta-privacy-policy/")}>Privacy Policy</span> and <span className="privacy-policy-btn" onClick={() => handleIframeforTerms("https://centa.org/mycenta-terms-of-use/")}>Terms of Use</span>.</dd>
          <Button disabled={this.state.buttonDisabled} type="submit" onClick={this.handleCaptchaVerify} className="btn-block signin-btn mt-4">
            <b>SIGN UP</b>
          </Button>
          {IframeModal}
          {captchaPopUp}
          {this.state.showError ?
            <>
              {errorComponent}
              <p className="alertTextRedesign mt-2 mb-0"> {this.state.formErrors.mandatoryCheck} </p>
              <p className="alertTextRedesign mt-2 mb-0"> {this.state.formErrors.invalidPhoneNumber} </p>
              <p className="alertTextRedesign mt-2 mb-0"> {this.state.formErrors.invalidEmail} </p>
              <p className="alertTextRedesign mt-2 mb-0"> {this.state.formErrors.pwdNotMatches} </p>
              <p className="alertTextRedesign mt-2 mb-0"> {this.state.exceptionError} </p>
            </>
          : null}
          { this.state.showLoader ? <div align="center"> <Spinner animation="border" /></div> : null}
        </Form>
      )
    }

    return (
      <React.Fragment>
        <div className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4">
          <div className="ui-bg-overlay bg-dark opacity-25"></div>
          <div className="authentication-inner py-4">
            <Card>
              <div className="signup-top-container">
                <div className="h-50 w-100 mt-3 d-flex justify-content-center align-items-center">
                  <img src={centalogo} style={{width: "70%"}} alt="CentaLogo "/>           
                </div>
                <div className="h-25 w-100 mt-3">
                  <Row>
                    <Col className="default-font d-flex signin-top-txt">
                      <p className="mb-0">8,00,000 Teachers | 6500 locations | 68 countries</p>
                    </Col>
                 </Row>
                 <Row>
                   <Col className="default-font d-flex signin-top-txt">  
                     <p> Certification, Learning, Careers, Community</p> 
                   </Col>
                 </Row>  
               </div>
              </div>
              <div className="p-4 p-sm-4">
                {displayComponent}
              </div>
              <div className="py-3 px-4 px-sm-5 pb-4">
                <div className="text-center default-font signin-bottom-btn">
                  Already have an account? <a href="/pwa/signin">Sign In here!</a>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <InstallationPopup />
        <GoogleOneTapSignin handleOneTap={this.handleOneTap} />
      </React.Fragment>
    );
  }
}

function mapStateToProps({user}) {
  return {
    user
  };
}

function mapDispatchToProps(dispatch) {
return {
  userLogin: data=> dispatch(actions.userLogin(data))
}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
