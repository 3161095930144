import React, { Component } from 'react';
import { Form, Row,Col, Button,Card,Modal,Spinner} from 'react-bootstrap';
import '../App.css';
import * as api from '../api/api';
import * as actions from '../actions';
import { connect } from 'react-redux';
import UpdateRequired from '../layout/UpdateRequired'
import UserConfirmation from './UserConfirmation';
import ReactGA from 'react-ga';
import centalogo from '../assets/images/logo.png';
import '../vendor/styles/pages/authentication.scss'
import Iframe from 'react-iframe'
// import StatusMessage from './StatusMessage';
import InstallationPopup from './InstallationPopup'
import GoogleOneTapSignin from './onetapsignin/GoogleOneTapSignin'
import ReCAPTCHA from 'react-google-recaptcha';

class SignIn extends Component {
  constructor(props){
    super(props);
    this.state={
        username: '',
        password: '',
        confirmOtp: '',
        errorMsg: '',
        showLoader: false,
        buttonDisabled: false,
        showComponent: "userNameComponent",
        showModal: false,
        showError: false,
        iframeURL: '',
        prevPath: '',
        showCaptchaPopUp: false,
        reCaptchaToken: ''
    }
  }

  componentDidMount() {
    ReactGA.initialize('G-9KWCK6VCXB');
    ReactGA.pageview('pwa/signin');
    if (this.props.user.accessToken) {
      this.props.history.push('/pwa/home');
    }
    if(this.props.location.state) {
      this.setState({prevPath: this.props.location.state})
    }
  }

  SignIn = event => {
    this.setState({
      [event.target.id]: event.target.value,
      showDiv: 0
    });
  }

  handleUserName = () => {
    //e.preventDefault();
    //if(this.state.username === ''){
    //  return this.setState({errorMsg: 'Username is required.', buttonDisabled:false, showLoader:false, showError: true})
    //}
    let userName = {}
    userName.user_name = this.state.username
    api.handleUserName(userName)
    .then( (response) => {
      this.setState({showLoader: false})
      if(response.status === "success") {
        this.setState({showComponent: response.pwd_change_reqd, errorMsg: '',})
      }
      else {
        this.setState({errorMsg: response.message,buttonDisabled:false,showLoader:false, showError: true})
      }
    })
    .catch(error => this.setState({errorMsg: 'Error in Signin, Please check your internet or Contact the Admin', buttonDisabled:false, showLoader:false, showError: true}));
  }

  handleSignIn = (e) => {
    e.preventDefault();
    this.setState({showLoader: true,buttonDisabled: true})
     const obj = {}
      if (this.state.password === '') {
        return this.setState({errorMsg: 'Password is required.', buttonDisabled:false, showLoader:false, showError: true})
      }
    let SignIninfo = {}
    SignIninfo.user_name=this.state.username
    SignIninfo.password=this.state.password
    ReactGA.initialize('G-9KWCK6VCXB');
    ReactGA.event({
      category: 'Signin',
      action: 'Click/signin',
      label: 'user/signin'
    });
    api.signIn(SignIninfo)
    .then((response) => {
      this.setState({showLoader: false})
      if (response.status === "success") {
        obj.accessToken = response.headers.accessToken            
        obj.userName = response.data.user_name
        obj.role = response.data.role
        obj.id = response.data.id
        obj.otherRoles = response.data.other_roles
        obj.image = ''
        obj.pdHours = 0
        obj.myBadges =  ''
        let currentDate = new Date()
        // Getting previous hour from current date and hour
        let prevHour = new Date(currentDate.getTime() - (1000 * 60 * 60));
        obj.pdHoursExpTime = prevHour
        this.props.userLogin(obj)
        if(response.data.role === 'user') {
          this.state.prevPath ? this.props.history.push(this.state.prevPath) : this.props.history.push('/pwa/home')
        } else if (response.data.role === 'school_organization_admin') {
          this.state.prevPath ? this.props.history.push(this.state.prevPath) : this.props.history.push('/pwa/scl/admin/home')
        } else if (response.data.role === 'institution_admin') {
          this.props.history.push('/pwa/institution_admin/home')
        }
      }
      else if (response.status === "error") {
        this.setState({errorMsg: response.message, buttonDisabled:false, showLoader:false, showError: true})
      }
      else if (response.status === "inactive") {
        this.setState({showComponent: "yes"})
      }
    })
    .catch(error => this.setState({errorMsg: 'Error in Signin, Please check your internet or Contact the Admin', buttonDisabled:false, showLoader:false, showError: true}));
  }

  redirectPostLogin =(pwdChangeReqd, role) => {
    if(pwdChangeReqd === 'yes') {
      if (role === 'school_organization_admin') {
        this.props.history.push('/pwa/scl/admin/reset/password')
      } else {
        this.props.history.push('/pwa/reset/password')
      }
    }
    else{
      this.state.prevPath ? this.props.history.push(this.state.prevPath) : this.props.history.push('/pwa/home')
    }
  }


  handleOTPComponent = (e,type) => {
    e.preventDefault()
    this.props.history.push(`/pwa/otp/${type}`)
  }

  handleIframeforTerms = (iframeURL) => {
    this.setState({showModal: true, iframeURL: iframeURL})
  }

  handleIframeClose = () => {
    this.setState({showModal: false})
  }

  handleOneTap = () => {
    window.location.reload(true)
  }

  handleUserNameCheck = (e) => {
    e.preventDefault();
    if(this.state.username === ''){
      return this.setState({errorMsg: 'Username is required.', buttonDisabled:false, showLoader:false, showError: true})
    }
    else{
      this.setState({showCaptchaPopUp : true, errorMsg: ''});
    }
  }

  hideCaptchaPopUp = () => {
    this.setState({ showCaptchaPopUp: false });
  }

  handleOnChange = (value) => {
    if (value != null) {
      this.setState({showCaptchaPopUp: false });
      this.setState({ reCaptchaToken: value });
      //if(this.handleUserNameCheck()){
      let userName = {}
      userName.recaptcha_token=this.state.recaptcha_token
      userName.user_name = this.state.username
      api.handleUserName(userName)
      .then( (response) => {
      this.setState({showLoader: false})
      if(response.status === "success") {
        this.setState({showComponent: response.pwd_change_reqd, errorMsg: '',})
        }
        else {
          this.setState({errorMsg: response.message,buttonDisabled:false,showLoader:false, showError: true})
         }
       })
      .catch(error => this.setState({errorMsg: 'Error in Signin, Please check your internet or Contact the Admin', buttonDisabled:false, showLoader:false, showError: true}));
      }
            //this.handleUserName();
    //}
  }

  render() {
    let userNameComponent, otpComponent, passwordComponent, captchaPopUp;
    let handleOTPComponent = this.handleOTPComponent
    let IframeModal
    let handleIframeforTerms = this.handleIframeforTerms

    

    if(this.state.showModal) {
      IframeModal = (
        <Modal show={this.state.showModal} onHide={this.handleIframeClose}  size="lg" centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body align="center">
            <div>
              <Iframe url={this.state.iframeURL}
                position="relative"
                width="100%"
                id="myId"
                className="myClassname"
                height="1250px"
                allowFullScreen
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }
    captchaPopUp = (<Modal  show={this.state.showCaptchaPopUp} onHide={this.hideCaptchaPopUp} backdrop='static' keyboard={false} centered>
    <Modal.Header><h3>Are you a human?</h3></Modal.Header>
    <Modal.Body>
      <div align="center">
        <ReCAPTCHA sitekey='6Lf2ZRwiAAAAAAo8b9ftV_6eeqIAXDIPkKzAk5it'
          onChange={this.handleOnChange}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'right' }} className='mt-2'>
        <Button onClick={this.hideCaptchaPopUp} className="hp-form-cancel">
          Cancel
        </Button>
      </div>
    </Modal.Body>
  </Modal>)
    

    if(this.state.showComponent === "userNameComponent"){
      userNameComponent = (   
        <React.Fragment>
          <h6 className="heading-txt">WELCOME BACK!</h6>
          <Form>
            <Form.Group controlId="username" className='float-wrapper last mb-0 pb-0'>
              <Form.Label className='float-label'>Username</Form.Label>
              <Form.Control className='float-input' value={this.state.username} onChange={this.SignIn} />
            </Form.Group>
            <div className="d-flex justify-content-between align-items-center m-0 mb-2">
              {/* <Form.Check type="hidden"  className="m-0"/> */}
              <Button className="btn-block signin-btn"  
              type="submit" 
              onClick={this.handleUserNameCheck}
              disabled={this.state.buttonDisabled}>SUBMIT</Button>
            </div>
            <Form.Group>
              <Form.Label className="d-flex justify-content-between align-items-end">
                <div></div>
                <a href="#" onClick={((e) => handleOTPComponent(e,'forgot'))} className="d-block forget-btn">Forgot Username/ Password?</a>
              </Form.Label>
            </Form.Group>
          </Form>
        </React.Fragment>
      );
    }
    else if (this.state.showComponent === "yes") {
      otpComponent = (
        <UserConfirmation userName = {this.state.username} onRedirectPostLogin={this.redirectPostLogin}/>
      );
    }
    else {
      passwordComponent = (
        <Form>
          <Form.Group controlId="password" className='float-wrapper last mb-0 pb-0'>
            <Form.Label className='float-label'>Password</Form.Label>
            <Form.Control className='float-input' type="password" value={this.state.password} onChange={this.SignIn} />
          </Form.Group>
          {/* <dd className="text-muted" align="center">By continuing, you agree to MyCENTA's <b onClick={() => handleIframeforTerms("https://centa.org/mycenta-privacy-policy/")}>Privacy Policy</b> and <b onClick={() => handleIframeforTerms("https://centa.org/mycenta-terms-of-use/")}>Terms of Use</b>.</dd> */}
          <div className="d-flex justify-content-between align-items-center m-0 mb-2">
            {/* <Form.Check type="hidden"  className="m-0"/> */}
            <Button 
            className="btn-block signin-btn"   
            type="submit" 
            onClick={this.handleSignIn}
            disabled={this.state.buttonDisabled}>
              LOGIN
            </Button>
          </div>
          <Form.Group>
            <Form.Label className="d-flex justify-content-between align-items-end">
              <div></div>
              <a href="#" onClick={((e) => handleOTPComponent(e,'forgot'))} className="d-block forget-btn">Forgot Username/ Password?</a>
            </Form.Label>
          </Form.Group>
          {/* {IframeModal} */}
          <a href="/pwa/signin" className="d-block text-center forget-btn" align="center">Back to Sign In</a>
        </Form>
      );
    }

    return (
      <div className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4">
        <div className="ui-bg-overlay bg-dark opacity-25"></div>
        <div className="authentication-inner py-2"> 
         <Card>
            <div className="signin-top-container">
              <div className="h-50 w-100 mt-3 d-flex justify-content-center align-items-center">
                <img src={centalogo} style={{width: "70%"}} alt="CentaLogo "/>           
              </div>
              <div className="h-25 w-100 mt-3">
                <Row>
                  <Col className="default-font d-flex signin-top-txt">
                    <p className="mb-0">8,00,000 Teachers | 6500 locations | 68 countries</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="default-font d-flex signin-top-txt">  
                    <p> Certification, Learning, Careers, Community</p> 
                  </Col>
                </Row>  
              </div>
            </div> 
            <div className="p-4 p-sm-4">  
              {userNameComponent}
              {otpComponent}
              {passwordComponent}
              {captchaPopUp}
              {this.state.showError  ? <p className="alertTextRedesign mb-0 mt-2">{this.state.errorMsg}</p> : null}
              {this.state.showLoader ? <div align="center"><Spinner animation="border" /></div>   : null}
            </div>
            <div className="py-4 px-4 px-sm-4">
              <div className="text-center text-muted default-font signin-bottom-btn">
                Don't have an account yet? <a  href="/pwa/signup">Sign Up here!</a>
              </div>
            </div>
          </Card>
          <UpdateRequired />
          <InstallationPopup />
          <GoogleOneTapSignin handleOneTap={this.handleOneTap} />
        </div>
      </div>
    );
  }
}

function mapStateToProps({user}) {
  return {
    user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data)),
    adminLogin: data => dispatch(actions.adminLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
