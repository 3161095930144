import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Sidenav from '../../vendor/libs/sidenav'
import layoutHelpers from './helpers'
import logo_img from '../../assets/images/c.png'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'

class LayoutSidenav extends Component {
  layoutSidenavClasses() {
    let bg = this.props.sidenavBg

    if (this.props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      this.props.orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0 && this.props.orientation !== 'horizontal'
  }

  render() {
    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>

        {/* Brand demo (see src/demo.css) */}
        {this.props.orientation !== 'horizontal' && (
          <React.Fragment>
            <div className="app-brand demo">
              <span className="app-brand-logo demo">
                <img src={logo_img} alt="logo.png" style={{ height: '25px' }} />
              </span>
              <Link to="/pwa/home" className="app-brand-text demo sidenav-text font-weight-normal ml-2">MyCENTA</Link>
              <a href="#toggle" className="layout-sidenav-toggle sidenav-link text-large ml-auto" onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu align-middle"></i>
              </a>
            </div>
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}

        <div className={`sidenav-inner ${this.props.orientation !== 'horizontal' ? 'py-1' : ''}`}>
          {(!this.props.user || !this.props.user.accessToken) &&
            <React.Fragment>
              <a className="sidenav-item d-block">
                <div className="sidenav-link "><div>Access these sections by signing in/ signing up</div></div>
              </a>
              <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/signin">Sign in</Sidenav.RouterLink>
            </React.Fragment>}


          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/get/certified/home" style={{ borderBottom: '1px solid white' }}>Get Certified</Sidenav.RouterLink>
          {/* <a className="sidenav-item d-block " href="https://centa.org/jobs/"> */}
          <a className="sidenav-item d-block " href="/pwa/careers/0/0">
            <div className="sidenav-link ">
              <i className="sidenav-icon fas fa-user-tie"></i>
              <div>Careers</div>
            </div>
          </a>
          <Sidenav.RouterLink icon="fas fa-user-tie" to="/pwa/post/home" style={{ borderBottom: '1px solid white' }}>Post Opportunities</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-book-open" to="/pwa/learning/spaces/learn">Learning Resources</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-chalkboard" to="/pwa/webinarSeries">CENTA Webinar Series</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-award" to="/pwa/livetrainings">Live Trainings</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fab fa-leanpub" to="/pwa/affiliated_programs">Affiliated Partner Resources & Toolkit</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fab fa-leanpub" to="/pwa/courses/selfpaced/3">Self-Paced Courses</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-bookmark" to="/pwa/blog/1" style={{ borderBottom: '1px solid white' }}>MyCENTA Blog</Sidenav.RouterLink>
          {(this.props.user && this.props.user.accessToken) &&
            < Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/logout">Log out</Sidenav.RouterLink>}
          <a className="sidenav-item d-block " href="https://centa.org/" target="_blank">
            <div className="sidenav-link "><div>Visit CENTA Website</div></div>
          </a>
        </div>
      </Sidenav>
    )
  }
}

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

LayoutSidenav.defaultProps = {
  orientation: 'vertical'
}

export default connect(store => ({
  sidenavBg: store.theme.sidenavBg
}))(LayoutSidenav)
