import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import layoutHelpers from './helpers'
import logo_img from '../../assets/images/c.png'
import * as api from '../../api/api'
import LayoutProfileSection from '../../views/LayoutProfileSection'

class LayoutNavbar extends Component {
  constructor(props) {
    super(props)
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  }

  componentDidMount() {
    if(this.props.user.accessToken &&
        localStorage.getItem('subscription') &&
        (localStorage.getItem('subscriptionSave') === 'false' || !localStorage.getItem('subscriptionSave'))
      ) {
        let subscription = {'subscription' : localStorage.getItem('subscription')}
        api.postValuesApi(this.props.user.accessToken, 'user/notification/details', subscription)
          .then((result) => {
            if (result.status === 'success') {
              localStorage.setItem('subscriptionSave', true)
            } else if (result.status === 'error') {
              console.log('Notification error', result.message)
            } else if (result.errors) {
              console.log('Notification error', result.message)
            }
          })
          .catch((error) => {
            console.log('Error', error)
          })
      }
  }


  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  render() {
    return (
      <Navbar bg={this.props.navbarBg} expand="lg" className="layout-navbar align-items-lg-center container-p-x">
        {/* Brand demo (see src/demo.css) */}
        <Navbar.Brand as={NavLink} to="/pwa/home" className="app-brand demo d-lg-none py-0 mr-4">
          <span className="app-brand-logo demo">
            <img src={logo_img} alt="logo.png" style={{height: '25px'}}/>
          </span>
          <span className="app-brand-text demo font-weight-normal ml-2">MyCENTA</span>
        </Navbar.Brand>

        {/* Sidenav toggle (see src/demo.css) */}
        {this.props.sidenavToggle && (
          <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
            <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
              <i className="ion ion-md-menu text-large align-middle"></i>
            </Nav.Item>      
          </Nav>
        )}

        <div className="small tag-line md-lg-tagline">
          <span className="d-flex flex-column text-center">8,00,000 Teachers | 68 countries</span>
          Certification, Learning, Careers, Community
        </div>
        
        <div className="d-flex flex-column profile-section">
          <LayoutProfileSection originPage='user' />
        </div>
        <div className="small tag-line xs-sm-tagline">
          8,00,000 Teachers | 68 countries
          <br/>
          Certification, Learning, Careers, Community
        </div>
      </Navbar>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(store => ({
  navbarBg: store.theme.navbarBg
}), {mapStateToProps, mapDispatchToProps})(LayoutNavbar)
