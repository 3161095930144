import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import './../../../App.css'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import layoutHelpers from '../helpers'
import logo_img from '../../../assets/images/c.png'

class AdminTopNavBar extends Component {
  constructor(props) {
    super(props)
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  handleRedirection = (e) => {
    e.preventDefault()
    this.props.history.push('/pwa/school_org_admin/cart')
  }

  render() {
    let to
    let logoutUrl = '/pwa/admin/logout'
    if(this.props.user.role === 'customer_service_associate') {
      to = '/pwa/admin/modulecheck'
    } else if (this.props.user.role === 'functional_lead') {
      to = '/pwa/admin/view_reg_number'
    } else if (this.props.user.role === 'assessments_associate') {
      to = '/pwa/admin/reg/details/assessments_associate/regdata'
    }  else if (this.props.user.role === 'school_organization_admin') {
      to = '/pwa/scl/admin/home'
      logoutUrl = '/pwa/logout'
    } else if (this.props.user.role === 'opportunity_admin') {
      to = '/pwa/admin/post/home'
    } else if (this.props.user.role === 'admin') {
      to = '/pwa/admin/featured/items/configuration/home/1'
    }
    return (
      <Navbar bg={this.props.navbarBg} expand="lg" className="layout-navbar align-items-lg-center container-p-x">

        {/* Brand demo (see src/demo.css) */}
        <Navbar.Brand as={NavLink} to={to} className="app-brand demo d-lg-none py-0 mr-4">
          <span className="app-brand-logo demo">
            <img src={logo_img} alt="logo.png" style={{height: '25px'}}/>
          </span>
          <span className="app-brand-text demo font-weight-normal ml-2">MyCENTA</span>
        </Navbar.Brand>

        {/* Sidenav toggle (see src/demo.css) */}
        {this.props.sidenavToggle && (
          <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
            <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
              <i className="ion ion-md-menu text-large align-middle"></i>
            </Nav.Item>
          </Nav>
        )}

        <Navbar.Toggle />

        <Navbar.Collapse>
          {/* Divider */}
          <hr className="d-lg-none w-100 my-2" />

          

          <Nav className="align-items-lg-center ml-auto">

          {((this.props.user && this.props.user.accessToken) && (this.props.user.role === 'school_organization_admin')) &&
            <div className='navbar-cart' onClick={(e) => this.handleRedirection(e)}>
              <div className='navbar-cart-button'>
                <i className="ion ion-md-cart"></i>
              </div>
            </div>
          }

            {/* Divider */}
            <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1s">|</div>
            <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
              <Dropdown.Toggle as={Nav.Link}>
                <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                  <span className="px-1 mr-lg-2 ml-2 ml-lg-0">Settings</span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* this.props.user.otherRoles && this.props.user.otherRoles.length > 0 &&
                  <Dropdown.Item href='/pwa/switch/role/school_organization_admin'><i className="fas fa-exchange-alt"></i> &nbsp; Switch Role</Dropdown.Item>
                */}
                <Dropdown.Item href={logoutUrl}><i className="fas fa-power-off"></i> &nbsp; Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

AdminTopNavBar.propTypes = {
  sidenavToggle: PropTypes.bool
}

AdminTopNavBar.defaultProps = {
  sidenavToggle: true
}

export default connect(store => ({
  navbarBg: store.theme.navbarBg
}))(AdminTopNavBar)
