import React, { Component } from 'react'
import { Container,Row,Col,ListGroup,Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';


class ListMenu extends Component{
	render(){
		return(
			<Container>
			<Row>
			<Col xs="12" sm="12" md="12">
			<ListGroup>
			<ListGroup.Item> Home </ListGroup.Item>
			<ListGroup.Item> Register </ListGroup.Item>
			<ListGroup.Item> Opportunities </ListGroup.Item>
			<ListGroup.Item> My Learning Plan </ListGroup.Item>
			<ListGroup.Item> Log out </ListGroup.Item>
			</ListGroup>
			</Col>
			</Row>
			</Container>
			);
	}
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data)),
    adminLogin: data => dispatch(actions.adminLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListMenu)