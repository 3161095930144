import React, { Component } from 'react'
import AdminTopNavBar from './AdminTopNavBar'
import AdminSideNav from './AdminSideNav'
import layoutHelpers from '../helpers'
import { connect } from 'react-redux';

class AdminLayout extends Component {
  closeSidenav(e) {
    e.preventDefault()
    layoutHelpers.setCollapsed(true)
  }

  componentDidMount() {
    layoutHelpers.init()
    layoutHelpers.update()
    layoutHelpers.setAutoUpdate(true)
  }

  componentWillUnmount() {
    layoutHelpers.destroy()
  }

  render() {
    return (
      <div className="layout-wrapper layout-2">
        <div className="layout-inner">
          <AdminSideNav {...this.props} />

          <div className="layout-container">
            <AdminTopNavBar {...this.props} />

            <div className="layout-content">
              <div className="container-fluid flex-grow-1 container-p-y">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
        <div className="layout-overlay" onClick={this.closeSidenav}></div>
      </div>
    )
  }
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
