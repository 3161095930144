import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as actions from '../../actions'
import { NavLink } from 'react-router-dom'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import layoutHelpers from './helpers'
import logo_img from '../../assets/images/c.png'
import * as api from '../../api/api'
import userPhoto from '../../assets/images/user_dashboard/user_photo.png'
import LayoutProfileSection from '../../views/LayoutProfileSection'

class LayoutNavbar extends Component {
  constructor(props) {
    super(props)
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  }

  componentDidMount() {
  }

  handleRedirection = (e) => {
    e.preventDefault()
    this.props.history.push('/pwa/school_org_admin/cart')
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  render() {
    return (
      <Navbar bg={this.props.navbarBg} expand="lg" className="layout-navbar align-items-lg-center container-p-x">
        <Navbar.Brand as={NavLink} to="/pwa/scl/admin/home" className="app-brand demo d-lg-none py-0 mr-4">
          <span className="app-brand-logo demo">
            <img src={logo_img} alt="logo.png" style={{height: '25px'}}/>
          </span>
          <span className="app-brand-text demo font-weight-normal ml-2">MyCENTA</span>
        </Navbar.Brand>

        {this.props.sidenavToggle && (
          <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
            <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
              <i className="ion ion-md-menu text-large align-middle"></i>
            </Nav.Item>      
          </Nav>
        )}
        <div className="d-flex">
          {(this.props.user && this.props.user.accessToken) && <div className='navbar-cart' onClick={(e) => this.handleRedirection(e)}>
            <div className='navbar-cart-button'>
              <i className="ion ion-md-cart"></i>
            </div>
          </div>}
          <div className="d-flex flex-column">
            <LayoutProfileSection originPage='school_org_admin' />
          </div>
        </div>
      </Navbar>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(store => ({
  navbarBg: store.theme.navbarBg
}), {mapStateToProps, mapDispatchToProps})(LayoutNavbar)
