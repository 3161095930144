import React, { Component } from 'react'
import layoutHelpers from './helpers'
import SclAdminNavbar from './SclAdminNavbar'
import { connect } from 'react-redux';
import SclAdminSideNav from './SclAdminSideNav';

class LayoutWithoutSidenav extends Component {
  closeSidenav(e) {
    e.preventDefault()
    layoutHelpers.setCollapsed(true)
  }

  componentDidMount() {
    // Enshure that we have not '.layout-expanded' class on the html element
    layoutHelpers._removeClass('layout-expanded')

    layoutHelpers.init()
    layoutHelpers.update()
    layoutHelpers.setAutoUpdate(true)
  }

  componentWillUnmount() {
    layoutHelpers.destroy()
  }
 
  render() {
    return (
      <div className="layout-wrapper layout-1 home-layout">
        <div className="layout-inner">
          <SclAdminNavbar {...this.props} />

          <div className="layout-container">
            <SclAdminSideNav {...this.props} />

            <div className="layout-content">
              {this.props.children}
            </div>
          </div>
        </div>
        <div className="layout-overlay" onClick={this.closeSidenav}></div>
      </div>
    )
  }
}

function mapStateToProps({ user, admin }) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWithoutSidenav)