import React, { Component } from 'react';
import * as api from '../api/api';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { Nav, Dropdown } from 'react-bootstrap'
import userPhoto from '../assets/images/user_dashboard/user_photo.png'

class LayoutProfileSection extends Component {

  constructor(props){
    super(props);
    this.state = {
    }
  }


  componentDidMount() {
    let date1 = new Date(this.props.user.pdHoursExpTime);
    let date2 = new Date();
		//To calculate hours between two dates
    let hours = Math.floor( (date2 - date1) / (60 * 60 * 1000) );
		if (this.props.user.accessToken && (isNaN(hours) === true || hours >= 1)) {
      const obj = {...this.props.user}
      api.getResponse(this.props.user.accessToken, 'profile/image')
        .then((result) => {
          if (result.status === 'success') {
            obj.image = result.avatar_url ? result.avatar_url : ''
            obj.pdHours = result.pd_hours ? result.pd_hours : 0
            obj.myBadges = result.is_badge_available === 'yes' ? true : false
            obj.pdHoursExpTime = new Date()
            this.props.userLogin(obj)
          } else if (result.errors && result.errors.includes('Not authenticated')) {
          } else {
            const obj = { ...this.props.user }
            obj.pdHoursExpTime = new Date()
            this.props.userLogin(obj)
          }
      })
      .catch(error => {
        const obj = {...this.props.user}
        obj.pdHoursExpTime = new Date()
        this.props.userLogin(obj)
      })
    }	
  }

  render() {
		let displayComponent, otherRoles = this.props.user.otherRoles, originPage = this.props.originPage
		displayComponent = (
			<React.Fragment>
				<Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
					<Dropdown.Toggle as={Nav.Link} className="profile-img-box">
						<span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
							{this.props.user.image ? (
								<img
									src={this.props.user.image}
									alt="."
									className= {`profile-avatar-img ${this.props.user.myBadges === true ? 'profile-img-border' : ''}`}
								/>
								) : (
								<img
									src={userPhoto}
									alt="."
                  className={`profile-avatar-img ${this.props.user.myBadges === true ? 'profile-img-border' : ''}`}
								/>
							)}
						</span>
					</Dropdown.Toggle>

          {originPage  === 'user' &&
            <Dropdown.Menu>
              <Dropdown.Item href="/pwa/myprofile/home"><i className="far fa-address-card"></i> &nbsp; My Profile</Dropdown.Item>
              <Dropdown.Item href="/pwa/my_registration/pending"><i className="ion ion-ios-filing"></i> &nbsp; My Registrations</Dropdown.Item>
              <Dropdown.Item href="/pwa/badge_home"><i className="fas fa-certificate"></i> &nbsp; My Badges</Dropdown.Item>
              <Dropdown.Item href="/pwa/learning_record"><i className="far fa-list-alt"></i> &nbsp; My Learning Record</Dropdown.Item>
              <Dropdown.Item href="/pwa/webinar_reports"><i className="fas fa-chalkboard-teacher"></i> &nbsp; My Webinar Participation Records</Dropdown.Item>
              <Dropdown.Item href="/pwa/vouchers"><i className="ion ion-ios-filing"></i> &nbsp; Vouchers</Dropdown.Item>
              <Dropdown.Item href="/pwa/rewardpoints"><i className="fas fa-medal"></i> &nbsp; MyCENTA Points</Dropdown.Item>
              { this.props.user.accessToken &&
                <Dropdown.Item href="/pwa/reset"><i className="fas fa-wrench"></i> &nbsp; Reset Username/ Password</Dropdown.Item>
              }
              {/* {this.props.user.accessToken && (Array.isArray(otherRoles) && (otherRoles.length !== 0)) && 
                <Dropdown.Item href="/pwa/switch/role/user">
                  <i className="fas fa-exchange-alt"></i> &nbsp; Switch Role
                </Dropdown.Item>
             } */}
            </Dropdown.Menu>
          }

          {originPage === 'school_org_admin' &&
            <Dropdown.Menu>
              <Dropdown.Item href="/pwa/scl/org/admin/profile"><i className="far fa-address-card"></i> &nbsp; School Profile</Dropdown.Item>
              {/* {this.props.user.otherRoles && this.props.user.otherRoles.length > 0 &&
                <Dropdown.Item href="/pwa/switch/role/school_organization_admin">
                  <i className="fas fa-exchange-alt"></i> &nbsp; Switch Role
                </Dropdown.Item>
              } */}
              { this.props.user.accessToken &&
                <Dropdown.Item href="/pwa/scl/admin/reset"><i className="fas fa-wrench"></i> &nbsp; Reset Username/ Password</Dropdown.Item>
              }
            </Dropdown.Menu>
          }
				</Dropdown>
        {(this.props.user.accessToken && this.props.user.role === 'user') &&
          (this.props.user.pdHours !== undefined && this.props.user.pdHours !== '') ?
          <div className="pd-hours-text px-2">PD Hours : {this.props.user.pdHours ? this.props.user.pdHours : 0} </div> : null
        }
			</React.Fragment>
		)
    return (
      displayComponent
    )
  } 
}
function mapStateToProps({ user }) {
  return {
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutProfileSection);
