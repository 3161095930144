import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../App.css'
import * as actions from '../actions'

class Logout extends Component {
  constructor(props){
    super(props);
    this.state={
      username: '',
      password: '',
      errorMsg: '',
    }
  }
  componentDidMount() {
    this.handleLogOut()
  }

  handleLogOut = () => {
    const obj={}, role = this.props.user.role;
    obj.userName = ''
    obj.accessToken = ''
    obj.role = ''
    obj.isAdministrator = ''
    obj.image = ''
    obj.otherRoles = ''
    obj.pdHours = 0
    obj.myBadges =  ''
    this.props.userLogout(obj)
    let cachesName = ['my-pwa-cache-v1']
    cachesName.map(cacheName => {
      return caches.delete(cacheName)
    })
    if ('serviceWorker' in navigator) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      })
    }
    if (
      role === 'opportunity_admin' || role === 'admin' || role === 'functional_lead' ||
      role === 'customer_service_associate' || role === 'assessments_associate'
    ) {
      this.props.history.push('/pwa/executive/signin')
    } 
    else if(role === 'school_organization_admin') {
      this.props.history.push('/pwa/scl/admin/home')
    }
    else {
      this.props.history.push('/pwa/home')
    }
  }
  
  render() {
    return (
      <div>
      </div>
    );
  }
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userLogout: data=> dispatch(actions.userLogout(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
