import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import layoutHelpers from './helpers'
import logo_img from '../../assets/images/c.png'

class LayoutNavbar extends Component {
  constructor(props) {
    super(props)
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  render() {
    return (
      <Navbar bg={this.props.navbarBg} expand="lg" className="layout-navbar align-items-lg-center container-p-x">

        {/* Brand demo (see src/demo.css) */}
        <Navbar.Brand as={NavLink} to="/pwa/home" className="app-brand demo d-lg-none py-0 mr-4">
          <span className="app-brand-logo demo">
            <img src={logo_img} alt="logo.png" style={{height: '25px'}}/>
          </span>
          <span className="app-brand-text demo font-weight-normal ml-2">MyCENTA</span>
        </Navbar.Brand>

        {/* Sidenav toggle (see src/demo.css) */}
        {this.props.sidenavToggle && (
          <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
            <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={this.toggleSidenav}>
              <i className="ion ion-md-menu text-large align-middle"></i>
            </Nav.Item>
          </Nav>
        )}

        <Navbar.Toggle />

        <Navbar.Collapse>
          {/* Divider */}
          <hr className="d-lg-none w-100 my-2" />

          

          <Nav className="align-items-lg-center ml-auto">

            {/* Divider */}
            <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

            <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
              <Dropdown.Toggle as={Nav.Link}>
                <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                  <span className="px-1 mr-lg-2 ml-2 ml-lg-0">Settings</span>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/pwa/myprofile/home"><i className="far fa-address-card"></i> &nbsp; My Profile</Dropdown.Item>
                <Dropdown.Item href="/pwa/app/about_centa"><i className="fas fa-info-circle"></i> &nbsp; About MyCENTA</Dropdown.Item>
                <Dropdown.Item href="/pwa/reset"><i className="fas fa-wrench"></i> &nbsp; Reset Username/ Password</Dropdown.Item>
                <Dropdown.Item href="/pwa/logout"><i className="fas fa-power-off"></i> &nbsp; Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
        <div className="small topBarTag tag-line">
          8,00,000 Teachers | 6500 locations | 68 countries
          <br/>
          Certification, Learning, Careers, Community
        </div>
      </Navbar>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

export default connect(store => ({
  navbarBg: store.theme.navbarBg
}))(LayoutNavbar)
