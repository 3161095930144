
export const USER_LOGIN = 'USER_LOGIN'
export const ADMIN_LOGIN = 'ADMIN_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'
export const USER_SIGNUP = 'USER_SIGNUP'

export function userLogin({
  id,
  email,
  accessToken,
  client,
  uid,
  phone,
  role,
  userName,
  image,
  isAdministrator,
  pdHours,
  pdHoursExpTime,
  myBadges,
  otherRoles
}) {
  return {
    type: USER_LOGIN,
    id,
    email,
    accessToken,
    client,
    uid,
    phone,
    role,
    userName,
    image,
    isAdministrator,
    pdHours,
    pdHoursExpTime,
    myBadges,
    otherRoles
  }
}

export function userLogout({ accessToken, client, uid }) {
  return {
    type: USER_LOGOUT,
    accessToken,
    client,
    uid,
  }
}

export function adminLogin({
  id,
  email,
  accessToken,
  client,
  uid,
  phone,
  role,
  userName,
  
}) {
  return {
    type: ADMIN_LOGIN,
    id,
    email,
    accessToken,
    client,
    uid,
    phone,
    role,
    userName,
  }
}

export function adminLogout({ accessToken, client, uid }) {
  return {
    type: ADMIN_LOGOUT,
    accessToken,
    client,
    uid,
  }
}



