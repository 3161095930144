import { BrowserRouter } from 'react-router-dom'
import { compose } from 'redux'
import React from 'react'
import ReactDOM from 'react-dom'
import throttle from 'lodash/throttle'

import App from './App'
import reducer from './reducers'
import * as serviceWorker from './serviceWorker';
// import './index.css'

// eslint-disable-next-line no-underscore-dangle
import { Provider } from 'react-redux';
import PersistStore from './configureStore';
import { PersistGate } from 'redux-persist/integration/react'
const { persistor, store } = PersistStore()


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const logger = store => next => (action) => {
  console.group(action.type)
  console.info('dispatching', action)
  const result = next(action)
  // console.log('next state', store.getState())
  console.groupEnd(action.type)
  return result
}

function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

let os = getOS();
if (os === 'iOS') {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}
Notification.requestPermission(function(status) {
  console.log('Notification permission status:', status)
});

