// configureStore.js

import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import rootReducer from './reducers'

import settings from './vendor/libs/theme-settings'

const persistConfig = {
  key: 'root',
  storage,
}

const getSettings = () => ({
  // Layout navbar color
  navbarBg: settings.getOption('navbarBg') || 'navbar-theme',

  // Layout sidenav color
  sidenavBg: settings.getOption('sidenavBg') || 'sidenav-theme',

  // Layout footer color
  footerBg: settings.getOption('footerBg') || 'footer-theme'
})

const initialState = getSettings()

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)

export default () => {
  return { store, persistor }
}