import React, { Component } from 'react';
import { Form, Container,Row,Col,Button, Card, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import '../App.css';
import * as api from '../api/api';


class HomePage extends Component {
  constructor(props){
    super(props);
    this.state={
      
        username: '',
        password: '',
        errorMsg: ''
    }
  }
  componentDidMount(){
    console.log(this.props)
    console.log(this.props.user.accessToken)
  }
  
  render() {
    return (
      <h1>Welcome {this.props.user.userName}!</h1>
    );
  }
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
