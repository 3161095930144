import React, { Component } from 'react';
import { Form, Container,Row,Col,Button, Card, ListGroup, Image, Modal,Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import '../App.css';
import * as api from '../api/api';
import centaLogo from '../assets/images/centa-logo-new.png';
import * as actions from '../actions';

class PasswordlessTokenLogin extends Component {

  constructor(props){
    super(props);
    this.state={
      errorMsg: '',
      modal: false,
      token: '',
      isTokenError: false,
      inactiveError: false,
    }
  }

  componentDidMount() {
    // Cache clearing
    let cachesName = ['my-pwa-cache-v1']
    cachesName.map(cacheName => {
      return caches.delete(cacheName)
    })
    if ('serviceWorker' in navigator) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      })
    }
    let param = this.props.location.search
    let paramSplit = param.split('&userid=')
    let tokenParam = paramSplit[0].split('=')
    let token = tokenParam[1]
    let apiInput
    if(paramSplit[1] === undefined){  
      apiInput = {
        'token': token
      }
    } else {
      apiInput = {
        'token': token,
        'userid': paramSplit[1]
      }
    }


    const obj = {}
    api.tokenLogin(apiInput)
      .then((result) => {
        console.log(result)
        const { status, data, errors } = result
        if (status === 'error') {
          this.setState({ isTokenError: true })
        }
        if (status === 'success') {
          //console.log('inside success')
          this.setState({ errorMsg: '' })
          obj.accessToken = result.headers.accessToken            
          //console.log('after setting header')
          obj.userName = data.user_name
          obj.id = data.id
          obj.role = data.role
          obj.otherRoles = result.data.other_roles
          this.props.userLogin(obj)
          this.props.history.push('/pwa/myprofile/home')
        }
        if(status === 'inactive'){
          this.setState({ inactiveError: true })
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: 'Error in Login. Please contact Admin' })
      })
  }

  render() {
    let tokenErrorMsg, activeErrorMsg
    if(this.state.isTokenError){
      tokenErrorMsg =(<div>
        <p>In case you are accessing through a mobile phone, please click here: <a href="https://m.centa.org/">m.centa.org</a></p>
      </div>)
    }
    if(this.state.inactiveError){
      activeErrorMsg =(<div>
        <p>You are not veified with mobile number. Please contact Admin</p>
      </div>)
    }
    return(
      <Container>
        <br/>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            {tokenErrorMsg}
            {activeErrorMsg}
          </Col>
          <Col xs="12" sm="12" md="12" lg="12">
            <p className="alertText">{this.state.errorMsg}</p>
          </Col>  
        </Row>
      </Container>
      );
  }
}

function mapStateToProps({user}) {
  return {
    user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data)),
    adminLogin: data => dispatch(actions.adminLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordlessTokenLogin);

// Versions
// 2022.09.03 PK - Changed the redirect URL from /pwa/home to /pwa/myprofile/home after token verification.