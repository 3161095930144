import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

class InstallationPopup extends Component {

  constructor(props){
    super(props);
    this.state ={
      showPopup: false
    }
  }

  getOS = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }

  componentDidMount(){
    let getOS = this.getOS()
    if( getOS === 'Android') {
    // Code to handle install prompt on desktop
      let deferredPrompt;
      const addBtn = document.querySelector('.add-button');
      if(addBtn) {
        addBtn.style.display = 'none';
        window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        addBtn.style.display = 'block';
        addBtn.addEventListener('click', (e) => {
          // hide our user interface that shows our A2HS button
          addBtn.style.display = 'none';
          // Show the prompt
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                this.setState({showPopup: false})
              } else {
                this.setState({showPopup: true})
              }
              deferredPrompt = null;
            });
          });
        });
      }
    }
  }



  render() {
    let getOS = this.getOS()
    let popupComponent;
    if( getOS === 'Android') {
      popupComponent = (
        <React.Fragment>
          <Row style={{bottom: '1vh', zIndex: '2', position: 'fixed', textAlign: 'center', right: '10vh'}}>
            <Col xs="12" sm="12">
              <Button className="add-button">
                Add MyCENTA to Home Screen
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )
    } else {
      popupComponent = (<div />)
    }
    return(
      popupComponent
    );
  } 
}

export default InstallationPopup;
