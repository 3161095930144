import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout2 from './shared/layouts/Layout2'
import LayoutBlank from './shared/layouts/LayoutBlank'
import AdminLayout from './shared/layouts/adminlayout/AdminLayout'
import HomeLayout from './shared/layouts/HomeLayout'
import SclAdminLayout from './shared/layouts/SclAdminLayout'
import InstitutionAdminLayout from './shared/layouts/institution_adminlayout/InstitutionAdminLayout'

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> })


// ---
// Default application layout

export const DefaultLayout = Layout2

// ---
// Document title template

export const titleTemplate = '%s - MyCENTA'

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/pwa/home'
export const routes = [
  // PWA Routes
  // Without authentication
  // Starts
  {
    path: '/',
    component: lazy(() => import('./views/home')),
    layout: HomeLayout
  },
  {
    path: '/pwa/home',
    component: lazy(() => import('./views/home')),
    layout: HomeLayout
  },
  {
    path: '/pwa/webinarSeries',
    component: lazy(() => import('./views/webinarSeries')),
    layout: HomeLayout
  },
  {
    path: '/pwa/webinarBlog/:blog_id/:type_name/:type_id/:value1/:value2',
    component: lazy(() => import('./views/landingpage/WebinarBlog')),
    layout: HomeLayout
  },
  // {
  //   path: '/pwa/cglreg',
  //   component: lazy(() => import('./views/cglRegistration')),
  //   layout: LayoutBlank
  // },
  {
    path: '/pwa/myprofile/home',
    component: lazy(() => import('./views/MyProfileHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/profile/:sectionName',
    component: lazy(() => import('./views/Profile')),
    layout: HomeLayout
  },
  {
    path: '/pwa/tqupdate/:certificationParticipationId',
    component: lazy(() => import('./views/TQUpdateForm')),
    layout: HomeLayout
  },
  {
    path: '/pwa/ew/scl/admin/registration',
    component: lazy(() => import('./views/ext_trainings/EwSchoolAdminRegistration')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/haryana/registration',
    component: lazy(() => import('./views/HaryanaRegistration')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/haryana/tgt/registration',
    component: lazy(() => import('./views/HaryanaRegistration')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/haryana/apc/registration',
    component: lazy(() => import('./views/HaryanaAPCRegistration')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/haryana/sen/registration/:type',
    component: lazy(() => import('./views/HaryanaSENRegistration')),
    layout: LayoutBlank
  },
  // {
  //   path: '/pwa/ew/registration',
  //   component: lazy(() => import('./views/ext_trainings/EwUserRegistration')),
  //   layout: LayoutBlank
  // },
  {
    path: '/pwa/signup',
    component: lazy(() => import('./views/SignUp')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/signin',
    component: lazy(() => import('./views/SignIn')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/logout',
    component: lazy(() => import('./views/Logout')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/otp/:type',
    component: lazy(() => import('./views/ForgotPassword')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/confirmotp',
    component: lazy(() => import('./views/ConfirmOtp')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/updaterequired',
    component: lazy(() => import('./layout/UpdateRequired')),
    layout: LayoutBlank
  },
  // {
  //   path: '/pwa/OpportunityWebForm',
  //   component: lazy(() => import('./views/administrator/opportunities/OpportunityWebForm')),
  //   layout: LayoutBlank
  // },
  {
    path: '/pwa/centa_tq_registration/:type',
    component: lazy(() => import('./views/CentaTQRegistration')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/centa_tpo_registration/:type',
    component: lazy(() => import('./views/CentaTPORegistration')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/kido/registration',
    component: lazy(() => import('./views/ext_trainings/KidoRegistrationForm')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/tokenlogin',
    component: lazy(() => import('./views/PasswordlessTokenLogin')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/tq_registration/:name/:type/:partialId/:formType',
    component: lazy(() => import('./views/TQRegistration')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/delhi_centa_tpo',
    component: lazy(() => import('./views/TQRegistrationDelhi')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/post/create/:type',
    component: lazy(() => import('./views/EmployerPortal/CreatePost')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/logout',
    component: lazy(() => import('./views/Logout'))
  },
  {
    path: '/pwa/user_restrict',
    component: lazy(() => import('./views/UserRestrict')),
    layout: LayoutBlank
  },
  // {
  //   path: '/pwa/license',
  //   component: lazy(() => import('./views/License')),
  //   layout: LayoutBlank
  // },
  // {
  //   path: '/pwa/walk_in_tq',
  //   component: lazy(() => import('./views/WalkinTq')),
  //   layout: LayoutBlank
  // },
  {
    path: '/pwa/webinar/:id',
    component: lazy(() => import('./views/landingpage/DetailedWebinar')),
    layout: HomeLayout
  },
  {
    path: '/pwa/webinar/post/registration/:id',
    component: lazy(() => import('./views/landingpage/WebinarPostRegistration')),
    layout: HomeLayout
  },
  {
    path: '/pwa/learning/resource/:id',
    component: lazy(() => import('./views/landingpage/DetailedLearningResource')),
    layout: HomeLayout
  },
  {
    path: '/pwa/blog_main/:pageNumber/:id',
    component: lazy(() => import('./views/revamped_version/blog/BlogMain')),
    layout: HomeLayout
  },
  {
    path: '/pwa/blog/:pageNumber',
    component: lazy(() => import('./views/revamped_version/blog/Blog')),
    layout: HomeLayout
  },
  {
    path: '/pwa/get/certified/home',
    component: lazy(() => import('./views/about/GetCertifiedHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/tq/learnmore',
    component: lazy(() => import('./views/ext_trainings/TQLearnMore')),
    layout: HomeLayout
  },
  {
    path: '/pwa/tpo/learnmore',
    component: lazy(() => import('./views/ext_trainings/TPOLearnMore')),
    layout: HomeLayout
  },
  {
    path: '/pwa/tq_registration/:name/:type/:partialId',
    component: lazy(() => import('./views/TQRegistration')),
    layout: HomeLayout
  },
  {
    path: '/pwa/tq_registration/:name/pvt',
    component: lazy(() => import('./views/TQRegistration')),
    layout: HomeLayout
  },
  {
    path: '/pwa/reset',
    component: lazy(() => import('./views/Reset')),
    layout: HomeLayout
  },
  {
    path: '/pwa/reset/:type',
    component: lazy(() => import('./views/ResetPassword')),
    layout: HomeLayout
  },
  {
    path: '/pwa/mc/home/:topicId',
    component: lazy(() => import('./views/ext_trainings/MCHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/mc/registration/:topicId',
    component: lazy(() => import('./views/MicroCredentialRegistration')),
    layout: HomeLayout
  },
  {
    path: '/pwa/mc/update/:certification_participation_id',
    component: lazy(() => import('./views/MCUpdateForm')),
    layout: HomeLayout
  },
  {
    path: '/pwa/ece/home',
    component: lazy(() => import('./views/ext_trainings/ECEHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/ece/registration',
    component: lazy(() => import('./views/ext_trainings/ECERegistrationForm')),
    layout: HomeLayout
  },
  {
    path: '/pwa/ece/update/:id',
    component: lazy(() => import('./views/ext_trainings/ECEUpdateForm')),
    layout: HomeLayout
  },
  {
    path: '/pwa/post/create',
    component: lazy(() => import('./views/EmployerPortal/CreatePost')),
    layout: HomeLayout
  },
  {
    path: '/pwa/post/update/:id',
    component: lazy(() => import('./views/EmployerPortal/UpdatePost')),
    layout: HomeLayout
  },
  {
    path: '/pwa/post/duplicate/:id',
    component: lazy(() => import('./views/EmployerPortal/DuplicatePost')),
    layout: HomeLayout
  },
  {
    path: '/pwa/post/view/:id',
    component: lazy(() => import('./views/EmployerPortal/ViewPost')),
    layout: HomeLayout
  },
  {
    path: '/pwa/post/home',
    component: lazy(() => import('./views/EmployerPortal/HomePost')),
    layout: HomeLayout
  },
  {
    path: '/pwa/post/response/:id/:subOppId',
    component: lazy(() => import('./views/EmployerPortal/ResponsePost')),
    layout: HomeLayout
  },
  {
    path: '/pwa/my_registration/:tab',
    component: lazy(() => import('./views/MyRegistrations')),
    layout: HomeLayout
  },
  {
    path: '/pwa/badge_home',
    component: lazy(() => import('./views/sertify/BadgesHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/my_reports',
    component: lazy(() => import('./views/MyPerformanceReports')),
    layout: HomeLayout
  },
  {
    path: '/pwa/learning/spaces/:type',
    component: lazy(() => import('./views/learningspaces/CentaLearningSpaces')),
    layout: HomeLayout
  },
  {
    path: '/pwa/livetrainings', //masterclasses replaced with livetrainings
    component: lazy(() => import('./views/revamped_version/masterclass/MasterClassesHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/livetrainings/package/ind/:id', //masterclasses replaced with livetrainings
    component: lazy(() => import('./views/revamped_version/masterclass/DetailedMasterClass')),
    layout: HomeLayout
  },
  {
    path: '/pwa/affiliated_programs',
    component: lazy(() => import('./views/revamped_version/affiliateprograms/AffiliateProgramsHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/affiliated_programs/packages/home/:id/:name',
    component: lazy(() => import('./views/revamped_version/affiliateprograms/AffiliateProgramPackages')),
    layout: HomeLayout
  },
  {
    path: '/pwa/careers/:searchKey/:searchValue',
    component: lazy(() => import('./views/Careers')),
    layout: HomeLayout
  },
  {
    path: '/pwa/careers/all/:oppTypeId/:searchKey/:searchValue/:pageNo',
    component: lazy(() => import('./views/CareersViewAll')),
    layout: HomeLayout
  },
  {
    path: '/pwa/opportunitydetails/:id',
    component: lazy(() => import('./views/opportunityDetails')),
    layout: HomeLayout
  },
  {
    path: '/pwa/webinar_reports',
    component: lazy(() => import('./views/UserWebinarReports')),
    layout: HomeLayout
  },
  {
    path: '/pwa/rewardpoints',
    component: lazy(() => import('./views/RewardPoints')),
    layout: HomeLayout
  },
  {
    path: '/pwa/rewardpoints/utilized',
    component: lazy(() => import('./views/RewardPointsUtilized')),
    layout: HomeLayout
  },
  {
    path: '/pwa/post_payment',
    component: lazy(() => import('./views/PostPayment')),
    layout: HomeLayout
  },
  {
    path: '/pwa/payment/mode/link',
    component: lazy(() => import('./views/DirectPaymentLink')),
    layout: HomeLayout
  },
  {
    path: '/pwa/direct/access/link',
    component: lazy(() => import('./views/DirectAccessLink')),
    layout: HomeLayout
  },
  {
    path: '/pwa/learning_record',
    component: lazy(() => import('./views/LearningRecords')),
    layout: HomeLayout
  },
  {
    path: '/pwa/voucher/redemptions',
    component: lazy(() => import('./views/VoucherRedemptions')),
    layout: HomeLayout
  },
  {
    path: '/pwa/voucher/utilizations',
    component: lazy(() => import('./views/VoucherUtilizations')),
    layout: HomeLayout
  },
  {
    path: '/pwa/vouchers',
    component: lazy(() => import('./views/Vouchers')),
    layout: HomeLayout
  },
  {
    path: '/pwa/courses/:courseType/:entityId',
    component: lazy(() => import('./views/revamped_version/courses/CoursesHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/courses/package/ind/:courseType/:id',
    component: lazy(() => import('./views/revamped_version/courses/DetailedCourseClass')),
    layout: HomeLayout
  },
  // {
  //   path: '/pwa/switch/role/user',
  //   component: lazy(() => import('./views/SwitchRole')),
  //   layout: HomeLayout
  // },
  {
    path: '/pwa/school_org_admin/master',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminMaster')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/school_org_admin/learning',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminLearning')),
    layout: SclAdminLayout
  },
  // {
  //   path: '/pwa/switch/role/school_organization_admin',
  //   component: lazy(() => import('./views/SwitchRole')),
  //   layout: SclAdminLayout
  // },
  {
    path: '/pwa/school_org_admin/masterclasses/package/:id',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminDetailedMaster')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/school_org_admin/affiliated_programs/package/:id/:name',
    component: lazy(() => import('./views/administrator/school_org_admin/sclAdminAffiliatePackages')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/admin/home',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminHome')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/org/admin/profile',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminProfileHome')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/org/admin/profile/:tabName',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminProfileDetails')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/school_org_admin/certifications',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminCertification')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/school_org_admin/cart',
    component: lazy(() => import('./views/administrator/school_org_admin/CartPage')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/admin/reset/:type',
    component: lazy(() => import('./views/ResetPassword')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/admin/reset',
    component: lazy(() => import('./views/Reset')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/admin/purchases',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminPurchaseList')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/admin/vouchers/list',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminVouchersList')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/licenses',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminLicensesList')),
    layout: SclAdminLayout
  },
  // {
  //   path: '/pwa/scl/admin/walkintq',
  //   component: lazy(() => import('./views/administrator/school_org_admin/SclAdminWalkinTq')),
  //   layout: SclAdminLayout
  // },
  {
    path: '/pwa/scl/admin/vouchers',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminVouchers')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/scl/admin/post_payment',
    component: lazy(() => import('./views/PostPayment')),
    layout: SclAdminLayout
  },
  // {
  //   path: '/pwa/allocations',
  //   component: lazy(() => import('./views/administrator/school_org_admin/SclAdminAllocationList')),
  //   layout: SclAdminLayout
  // },
  // {
  //   path: '/pwa/licenses/details/:id',
  //   component: lazy(() => import('./views/administrator/school_org_admin/SclAdminLicenseDetail')),
  //   layout: SclAdminLayout
  // },

  // Non User Routes
  {
    path: '/pwa/executive/signin',
    component: lazy(() => import('./views/administrator/AdministratorSignin')),
    layout: LayoutBlank
  },
  {
    path: '/pwa/admin/logout',
    component: lazy(() => import('./views/administrator/AdminLogout')),
    layout: LayoutBlank
  },
  // Ends
  // 
  // With Authentication
  // Starts
  {
    path: '/pwa/app/dashboard/:type',
    component: lazy(() => import('./views/Dashboard'))
  },
  {
    path: '/pwa/app/about_mc_certification',
    component: lazy(() => import('./views/about/AboutMCCertification'))
  },
  {
    path: '/pwa/app/about_tpo_india',
    component: lazy(() => import('./views/about/TPOIndia'))
  },
  {
    path: '/pwa/app/about_tpo_international',
    component: lazy(() => import('./views/about/TPOInternational'))
  },
  {
    path: '/pwa/app/tpo_registration',
    component: lazy(() => import('./views/TPORegistration'))
  },
  {
    path: '/pwa/app/tpo_registration/pvt',
    component: lazy(() => import('./views/TPORegistration'))
  },
  {
    path: '/pwa/app/tpo_registration/details',
    component: lazy(() => import('./views/TPORegistrationDetails'))
  },
  {
    path: '/pwa/app/mc_registration',
    component: lazy(() => import('./views/MCRegistration'))
  },
  {
    path: '/pwa/app/hallticket',
    component: lazy(() => import('./views/TPOHallTicket'))
  },
  {
    path: '/pwa/app/package/home/:id/:name',
    component: lazy(() => import('./views/coursepackage/CoursePackageHome'))
  },
  {
    path: '/pwa/app/package/orders',
    component: lazy(() => import('./views/coursepackage/MyPurchaseOrders'))
  },
  {
    path: '/pwa/app/my_submission',
    component: lazy(() => import('./views/submission/MySubmissions'))
  },
  // {
  //   path: '/pwa/app/opportunityhome',
  //   component: lazy(() => import('./views/OpportunityHome'))
  // },
  {
    path: '/pwa/app/opportunity/:id',
    component: lazy(() => import('./views/Opportunity'))
  },
  {
    path: '/pwa/app/learningplan',
    component: lazy(() => import('./views/LearningResources'))
  },
  {
    path: '/pwa/app/build_my_learning_plan/:id',
    component: lazy(() => import('./views/BuildMyLearningPlan'))
  },
  {
    path: '/pwa/app/aspect/:id/:name/:source/:competencyId',
    component: lazy(() => import('./views/LearningPlanAspect'))
  },
  {
    path: '/pwa/app/my_learning_plan/:month',
    component: lazy(() => import('./views/MyLearningPlan'))
  },
  {
    path: '/pwa/app/strength_and_dev_areas',
    component: lazy(() => import('./views/StrengthsDevAreas'))
  },
  {
    path: '/pwa/app/my_archives',
    component: lazy(() => import('./views/MyArchives'))
  },
  {
    path: '/pwa/app/competency',
    component: lazy(() => import('./views/Competency'))
  },
  {
    path: '/pwa/app/userdashboard',
    component: lazy(() => import('./views/UserDashboard'))
  },
  {
    path: '/pwa/app/about_centa',
    component: lazy(() => import('./views/about/AboutCENTA'))
  },
  {
    path: '/pwa/app/samplequiz',
    component: lazy(() => import('./views/SampleQuiz'))
  },
  {
    path: '/pwa/app/quizandsurvey',
    component: lazy(() => import('./views/QuizandSurvey'))
  },
  {
    path: '/pwa/app/quiziframe/:id/:name',
    component: lazy(() => import('./views/QuizIFrame'))
  },
  {
    path: '/pwa/app/survey',
    component: lazy(() => import('./views/Survey'))
  },
  {
    path: '/pwa/app/surveyiframe/:id',
    component: lazy(() => import('./views/SurveyIFrame'))
  },
  {
    path: '/pwa/app/share',
    component: lazy(() => import('./views/ShareFeature'))
  },
  {
    path: '/pwa/app/userrewards',
    component: lazy(() => import('./views/UserRewardDetails'))
  },
  {
    path: '/pwa/app/learningplanhome',
    component: lazy(() => import('./views/LearningPlanHome'))
  },
  {
    path: '/pwa/app/tpoupdate/:certificationParticipationId',
    component: lazy(() => import('./views/TPOUpdateForm'))
  },
  {
    path: '/pwa/app/package/:packageName/:id',
    component: lazy(() => import('./views/coursepackage/CoursePackageDetails'))
  },

  //Forum
  {
    path: '/pwa/app/forumhome',
    component: lazy(() => import('./views/ForumHome')),
    layout: HomeLayout
  },
  {
    path: '/pwa/app/forumaccess',
    component: lazy(() => import('./views/ForumAccess')),
    layout: HomeLayout
  },
  
 // Ends
  // {
  //   path: '/pwa/app/news_and_event',
  //   component: lazy(() => import('./views/NewsandEvent'))
  // },
  {
    path: '/pwa/app/package/main_home',
    component: lazy(() => import('./views/coursepackage/MainHome'))
  },
  {
    path: '/pwa/app/lr_blog/:competency_id/:id',
    component: lazy(() => import('./views/LearningResourceBlogMain'))
  },
  {
    path: '/pwa/app/tq_registration_details/:reg_status',
    component: lazy(() => import('./views/TQRegistrationDetails'))
  },
  {
    path: '/pwa/admin/modulecheck',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminEntityPurchaseDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/purchase_user_details',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminPurchaseUserDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/view_reg_number',
    component: lazy(() => import('./views/administrator/functionalleads/ViewRegistrationNumbers')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/view_discount_code',
    component: lazy(() => import('./views/administrator/functionalleads/ViewSpecificDiscountCode')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/configure_discount_code',
    component: lazy(() => import('./views/administrator/functionalleads/ConfigureDiscountCode')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/show_discount/:id',
    component: lazy(() => import('./views/administrator/functionalleads/AddUpdateDiscount')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/fl/cert/reg/data',
    component: lazy(() => import('./views/administrator/functionalleads/FunctionalLeadCertRegData')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/tq/registration/details',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminTqRegistrationDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/reg/details/assessments_associate/EwRegHome',
    component: lazy(() => import('./views/administrator/adminexports/EwRegistrationHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/reg/details/:role/:action',
    component: lazy(() => import('./views/administrator/adminexports/AdminExportDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/export/:role/:action',
    component: lazy(() => import('./views/administrator/adminexports/AdminExportUsingId')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/opportunities',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminOpportunitiesHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/opportunities/search',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminOpportunitiesSearch')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/opportunities/view',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminOpportunitiesView')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/opportunities/user/:id',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminOpportunitiesUsers')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/opportunities/candidate',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminOpportunitiesCandidates')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/opportunities/list/candidate/:id',
    component: lazy(() => import('./views/administrator/customerserviceassociate/AdminOpportunitiesList')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/post/home',
    component: lazy(() => import('./views/administrator/opportunities/OpportunityPostHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/post/create',
    component: lazy(() => import('./views/administrator/opportunities/CreateOpportunityPost')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/post/update/:opportunityId',
    component: lazy(() => import('./views/administrator/opportunities/UpdateOpportunityPost')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/post/duplicate/:opportunityId',
    component: lazy(() => import('./views/administrator/opportunities/DuplicateOpportunityPost')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/post/view/:opportunityId',
    component: lazy(() => import('./views/administrator/opportunities/OpportunityPostView')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/post/response/:opportunityId/:subOppId',
    component: lazy(() => import('./views/administrator/opportunities/OpportunityPostResponse')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/featured/items/configuration/home/:page',
    component: lazy(() => import('./views/administrator/admin/AdminFeaturedItemHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/featured/items/configuration/create/update/:id/:page',
    component: lazy(() => import('./views/administrator/admin/AdminFeaturedItemCreateUpdate')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/list/purchase/entities',
    component: lazy(() => import('./views/administrator/admin/AdminPurchaseEntitiesList')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/list/learning/resources',
    component: lazy(() => import('./views/administrator/admin/AdminLearningResourceList')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/list/user_details',
    component: lazy(() => import('./views/administrator/admin/user_details/AdminUserDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/list/update_user_details',
    component: lazy(() => import('./views/administrator/admin/user_details/AdminUserUpdate')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/reward/configration/home',
    component: lazy(() => import('./views/administrator/admin/AdminRewardConfigurationHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/notifications/home',
    component: lazy(() => import('./views/administrator/admin/AdminNotificationsHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/:role/import/:type/:uploadType',
    component: lazy(() => import('./views/administrator/admin/AdminImport')),
    layout: AdminLayout
  },
  {
    path: '/pwa/:role/history/:typeId/:uploadType',
    component: lazy(() => import('./views/administrator/admin/AdminImportHistory')),
    layout: AdminLayout
  },
  {
    path: '/pwa/:role/tqscores/home',
    component: lazy(() => import('./views/administrator/admin/AdminTqReportsHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/:role/tqregistration/home',
    component: lazy(() => import('./views/administrator/admin/AdminTqRegistrationHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/:role/tqscores/report/upload/details', 
    component: lazy(() => import('./views/administrator/admin/AdminTqReportDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/:role/notify/email/notification/:type', 
    component: lazy(() => import('./views/administrator/admin/AdminTqNotifyUser')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/notification/form/:type/:id',
    component: lazy(() => import('./views/administrator/admin/push_notification/AdminNotificationBuilder')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/user/notification/home',
    component: lazy(() => import('./views/administrator/admin/push_notification/NotificationHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/purchase_order/participation/home',
    component: lazy(() => import('./views/administrator/admin/AdminPurchaseOrderParticipation')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/user/contributions',
    component: lazy(() => import('./views/administrator/assessmentsassociate/UserContributionView')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/pwa_app_version',
    component: lazy(() => import('./views/administrator/admin/AdminAppVersion')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/tq/time/slots',
    component: lazy(() => import('./views/administrator/admin/TqTimeSlots')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/webinar_list',
    component: lazy(() => import('./views/administrator/admin/webinar/AdminWebinarList')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/seminar/change/:id',
    component: lazy(() => import('./views/administrator/admin/webinar/AdminWebinarAddChange')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/hallticket/upload/:type/:uploadType',
    component: lazy(() => import('./views/administrator/admin/webinar/AdminHallTicketUpload')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/hallticket/view/history/:typeId/:uploadType',
    component: lazy(() => import('./views/administrator/admin/webinar/AdminHallTicketUploadHistory')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/course/entity/home',
    component: lazy(() => import('./views/administrator/admin/course_package/AdminAddCourseEntityHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/course/entity',
    component: lazy(() => import('./views/administrator/admin/course_package/AdminAddCourseEntity')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/purchase/view',
    component: lazy(() => import('./views/administrator/admin/course_package/AdminPurchaseOrderView')),
    layout: AdminLayout
  },
  {
      path: '/pwa/admin/course/entity/list',
    component: lazy(() => import('./views/administrator/admin/course_package/AdminViewCourseEntities')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/course/entity/update/:id',
    component: lazy(() => import('./views/administrator/admin/course_package/AdminUpdateCourseEntities')),
    layout: AdminLayout
  },
  {
    path: '/pwa/institution_admin/home',
    component: lazy(() => import('./views/administrator/institution_admin/InstitutionAdminHome')),
    layout: InstitutionAdminLayout
  },
  {
    path: '/pwa/:role/tq_registration',
    component: lazy(() => import('./views/administrator/admin/TQRegisteredUsers')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/purchase_purchase/home',
    component: lazy(() => import('./views/administrator/admin/AdminLicenseHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/sme/home',
    component: lazy(() => import('./views/administrator/admin/AdminSMEHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/admin_cart_details',
    component: lazy(() => import('./views/administrator/admin/AdminCartDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/admin_license_details',
    component: lazy(() => import('./views/administrator/admin/AdminLicenseDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/admin_allocation_details',
    component: lazy(() => import('./views/administrator/admin/AdminAllocationDetails')),
    layout: AdminLayout
  },
  {
    path: '/pwa/school_org_admin/walkin/allocation/:product_type',
    component: lazy(() => import('./views/administrator/school_org_admin/SclAdminWalkinAllocation')),
    layout: SclAdminLayout
  },
  {
    path: '/pwa/admin/vouchers/home',
    component: lazy(() => import('./views/administrator/admin/AdminVouchersHome')),
    layout: AdminLayout
  },
  {
    path: '/pwa/admin/list/vouchers',
    component: lazy(() => import('./views/administrator/admin/AdminVouchersList')),
    layout: AdminLayout
  },
  // {
  //   path: '/pwa/admin/admin_license_allocation/details/:id',
  //   component: lazy(() => import('./views/administrator/admin/AdminLicenseAllocationDetail')),
  //   layout: AdminLayout
  // },
]


