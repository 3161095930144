import React, {Component} from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import '../App.css';
import * as api from '../api/api';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux'

class UpdateRequired extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isUpdateRequired: false,
      latestVersion: ''
    }
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.getOs = this.getOs.bind(this);
  }

  getOs() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;

  }

  componentDidMount() {
    let os = this.getOs();
    if (os === 'iOS'){
      api.getAppVersion()
      .then((responseJson) => {
        if (responseJson.status === 'success') {
          let version = responseJson.version
          let appVersion = localStorage.getItem('appVersion', version);
          if(!appVersion){
            localStorage.setItem('appVersion', version);
          }
          else if(appVersion && appVersion !== version) {
            this.setState({isUpdateRequired: true, latestVersion: version})
          }
        }
      })
      .catch(error => {
        console.error(error);
      })
    }
  }

  onClickUpdate () {
    localStorage.setItem('appVersion', this.state.latestVersion);
    window.location.reload(true);
  }

  render() {
    let updateComponent;
    if(this.state.isUpdateRequired === true){
      updateComponent = (<div className="updateButton" ><Button onClick={this.onClickUpdate} >New version available. Click here.</Button></div>)
    }
    else{
      updateComponent = ('')
    }
    return (
      <div>
        {updateComponent}
      </div>
    );
  }
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRequired)