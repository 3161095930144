import React, { Component } from 'react'
import { connect } from 'react-redux'

class LayoutFooter extends Component {
  prevent(e) {
    e.preventDefault()
  }

  render() {
    return (
      <nav className={`layout-footer footer bg-${this.props.footerBg}`} style={{zIndex: 2}}>
        <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
          <div>
            <a href="/pwa/home" className="footer-link pt-3 ml-4">Home</a>
          </div>
          <div>
            <a href="https://centa.org/jobs/" className="footer-link pt-3 ml-4">Careers</a>
            {/*<a href="/pwa/app/competency"  className="footer-link pt-3 ml-4">Learning</a>*/}
            <a href="/pwa/app/forumhome"  className="footer-link pt-3 ml-4">Forum</a>
          </div>
        </div>
      </nav>
    )
  }
}

export default connect(store => ({
  footerBg: store.theme.footerBg
}))(LayoutFooter)
