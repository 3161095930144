import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import '../App.css';
import * as api from '../api/api';
import * as actions from '../actions';
import { connect } from 'react-redux';

class UserConfirmation extends Component {

  constructor(props){
    super(props);
    this.state={
      errorMsg: '',
      confirmOtp: '',
      buttonDisable: false,
      regenButtonDisable: false,
      genOTPCountError: '',
      genOtpCount: 0,
      showError: false
    }
  }

  componentDidMount() {

  }

  handleUserConfirmation = (e) => {
    e.preventDefault();
    const obj = {}
    if (this.state.confirmOtp === '') {
      return this.setState({errorMsg: 'OTP is required.',showError: true})
    }
    let SignIninfo = {}
    SignIninfo.user_name = this.props.userName
    SignIninfo.confirm_otp = this.state.confirmOtp
    api.handleOtpSignIn(SignIninfo)
    .then((response) => {
    if(response.status === "success"){
      obj.accessToken = response.headers.accessToken            
      obj.userName = response.data.user_name
      obj.role = response.data.role
      obj.id = response.data.id
      obj.otherRoles = response.data.other_roles
      this.props.userLogin(obj)
      this.props.onRedirectPostLogin(response.data.pwd_change_reqd, response.data.role)
    }
    else if(response.status === "error"){
      this.setState({errorMsg: response.errors, showError: true})
    }
    else{
      this.setState({errorMsg: 'Please check the username/password', showError: true})
    }
    })
    .catch(error => this.setState({errorMsg: 'Error in Signin, Please check your internet or Contact the Admin', showError: true}));
  }

  inputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleRegenerateOTP = (e) =>{
    e.preventDefault();
    let otpGenCount
    otpGenCount = this.state.genOtpCount
    this.setState({errorMsg: '',regenButtonDisable: true})
    let  values = {
      'user_name': this.props.userName
    }
    if(this.state.genOtpCount > 2){
      return this.setState({genOTPCountError: 'You have exceeded maximum number of OTP regenerations allowed. Please contact team@centa.org',errorMsg: '',regenButtonDisable: false, showError: true})
    } 
    api.regenerateOTP(values)
      .then((result) => {
        if (result.status === 'error') {
          this.setState({ errorMsg: result.errors, regenButtonDisable: false, showError: true})
        }
        if (result.status === 'success') {
          otpGenCount = otpGenCount + 1
          this.setState({ errorMsg: result.message,regenButtonDisable: false,genOtpCount: otpGenCount, showError: true})
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: 'Error in confirmation', buttonDisable:false, showError: true})
      })
  }

  render() {
    return (
      <form>
        <p className="default-font font-weight-bold">Please enter the OTP received on Email/ SMS.</p>
        <Form.Group controlId="confirmOtp" className="float-wrapper last mb-0 pb-0">
          <Form.Label className="float-label">Enter the OTP </Form.Label>
          <Form.Control type="number" className="float-input" value={this.state.confirmOtp} onChange={this.inputChange} />
        </Form.Group>
        <div className="m-0">
          {/* <Form.Check type="hidden"  className="m-0"/> */}
          <Form.Group>
            <Button className="btn-block signin-btn" disabled={this.state.buttonDisabled} type="submit" onClick={this.handleUserConfirmation}>VERIFY </Button>
          </Form.Group>
        </div>
        <div className="d-flex justify-content-between align-items-center m-0">
          <Form.Check type="hidden"  className="m-0"/>
          <Form.Group>
            <Button disabled={this.state.regenButtonDisable} className="hp-form-register btn btn-primary btn-md" type="submit" onClick={this.handleRegenerateOTP}>REGENERATE</Button>
          </Form.Group>
        </div>
        {this.state.showError ?
          <>
            <p className = "alertTextRedesign mt-2 mb-0">{this.state.errorMsg}</p>
            <p className = "alertTextRedesign mt-2 mb-0">{this.state.genOTPCountError}</p>
          </>
        : null}
        <a href="/pwa/signin" className="d-block default-font" align="center">Back to Sign In</a>
      </form>
    );
  }
}

function mapStateToProps({user}) {
  return {
    user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data)),
    adminLogin: data => dispatch(actions.adminLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserConfirmation)