import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Sidenav from '../../../vendor/libs/sidenav'
import layoutHelpers from '../helpers'
import logo_img from '../../../assets/images/c.png'

class InstitutionAdminSideNav extends Component {
  layoutSidenavClasses() {
    let bg = this.props.sidenavBg

    if (this.props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      this.props.orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0 && this.props.orientation !== 'horizontal'
  }

  render() {
    let menuItem, linkTo
    if(this.props.user.role === 'institution_admin'){
      menuItem = (
        <React.Fragment>
          <Sidenav.RouterLink icon="ion ion-home" to="/pwa/institution_admin/home">Home Page</Sidenav.RouterLink>
        </React.Fragment>
      )
      linkTo = "/pwa/institution_admin/home"
    }

    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>

        {this.props.orientation !== 'horizontal' && (
          <React.Fragment>
            <div className="app-brand demo">
              <span className="app-brand-logo demo">
                <img src={logo_img} alt="logo.png" style={{height: '25px'}}/>
              </span>
              <Link to={linkTo} className="app-brand-text demo sidenav-text font-weight-normal ml-2">MyCENTA</Link>
              <a href="#toggle" className="layout-sidenav-toggle sidenav-link text-large ml-auto" onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu align-middle"></i>
              </a>
            </div>
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}

        <div className={`sidenav-inner ${this.props.orientation !== 'horizontal' ? 'py-1' : ''}`}>
          {menuItem}
        </div>
      </Sidenav>
    )
  }
}

InstitutionAdminSideNav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

InstitutionAdminSideNav.defaultProps = {
  orientation: 'vertical'
}

export default connect(store => ({
  sidenavBg: store.theme.sidenavBg
}))(InstitutionAdminSideNav)
