import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Sidenav from '../../vendor/libs/sidenav'
import layoutHelpers from './helpers'
import logo_img from '../../assets/images/c.png'

class LayoutSidenav extends Component {
  layoutSidenavClasses() {
    let bg = this.props.sidenavBg

    if (this.props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      this.props.orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0 && this.props.orientation !== 'horizontal'
  }

  render() {
    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>

        {/* Brand demo (see src/demo.css) */}
        {this.props.orientation !== 'horizontal' && (
          <React.Fragment>
            <div className="app-brand demo">
              <span className="app-brand-logo demo">
                <img src={logo_img} alt="logo.png" style={{height: '25px'}}/>
              </span>
              <Link to="/pwa/home" className="app-brand-text demo sidenav-text font-weight-normal ml-2">MyCENTA</Link>
              <a href="#toggle" className="layout-sidenav-toggle sidenav-link text-large ml-auto" onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu align-middle"></i>
              </a>
            </div>
            <div className="small sideBarTag tag-line" style={{padding: '0'}}>
              8,00,000 Teachers | 6500 locations | 68 countries
              <br/>
              Certification, Learning, Careers, Community
            </div>
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}

        {/* Links */}
        <div className={`sidenav-inner ${this.props.orientation !== 'horizontal' ? 'py-1' : ''}`}>

          {/* MyCENTA Menu */}
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/get/certified/home">Get Certified</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-book-open" to="/pwa/learning/spaces/learn">Learning Resources</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-award" to="/pwa/masterclasses">Masterclasses</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fab fa-leanpub" to="/pwa/affiliated_programs">Affiliated Partner Resources</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-ios-filing" to="/pwa/my_registration/pending">My Registrations</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-user-tie" to="/pwa/post/home">Post Opportunities</Sidenav.RouterLink>
          {/* <Sidenav.RouterLink icon="ion ion-md-folder" to="/pwa/app/my_submission">My Submissions</Sidenav.RouterLink> */}
          {/* <Sidenav.RouterLink icon="ion ion-md-analytics" to="/pwa/app/my_reports">My Performance Reports</Sidenav.RouterLink> */}
          <Sidenav.RouterLink icon="fas fa-chalkboard" to="/pwa/webinarSeries">CENTA Webinar Series</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-chalkboard-teacher" to="/pwa/webinar_reports">My Webinar Participation Records</Sidenav.RouterLink>
          {/* <Sidenav.RouterLink icon="fas fa-certificate" to="/pwa/badge_home">My Achievements</Sidenav.RouterLink> */}
          {/* <Sidenav.RouterLink icon="fas fa-shopping-cart" to="/pwa/app/package/orders">My Orders</Sidenav.RouterLink> */}
          {/* <Sidenav.RouterLink icon="fas fa-question" to="/pwa/app/quizandsurvey">Quizzes and Surveys</Sidenav.RouterLink> */}
          <Sidenav.RouterLink icon="fas fa-bookmark" to="/pwa/blog/1">MyCENTA Blog</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-medal" to="/pwa/rewardpoints">MyCENTA Points</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/logout">Logout</Sidenav.RouterLink>
        </div>
      </Sidenav>
    )
  }
}

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

LayoutSidenav.defaultProps = {
  orientation: 'vertical'
}

export default connect(store => ({
  sidenavBg: store.theme.sidenavBg
}))(LayoutSidenav)
