import { combineReducers } from 'redux'
import theme from './themeStore'

import {
  USER_LOGIN,
  USER_LOGOUT,
  ADMIN_LOGIN,
  ADMIN_LOGOUT
} from '../actions'

function user(state = {}, action) {
  let newState = {}
  console.log(action)
  switch (action.type) {
    case USER_LOGIN:
      newState.id = action.id
      //newState.email = action.email
      newState.accessToken = action.accessToken
      //newState.client = action.client
      //newState.uid = action.uid
      newState.userName = action.userName
      newState.role = action.role
      newState.image = action.image
      newState.pdHours = action.pdHours
      newState.pdHoursExpTime = action.pdHoursExpTime 
      newState.myBadges = action.myBadges
      newState.otherRoles = action.otherRoles
      return newState
    case USER_LOGOUT:
      newState.id = action.id
      //newState.email = ''
      newState.accessToken = ''
      //newState.client = ''
      //newState.uid = ''
      newState.userName = ''
      newState.role = ''
      newState.image = ''
      newState.expireTime = ''
      newState.pdHours = ''
      newState.pdHoursExpTime = ''
      newState.myBadges = ''
      newState.otherRoles = ''
      return newState
    default:
      return state
  }
}


function admin(state = {}, action) {
  let newState = {}

  switch (action.type) {
    case ADMIN_LOGIN:
      newState.id = action.id
      newState.email = action.email
      newState.accessToken = action.accessToken
      newState.role = action.role
      newState.client = action.client
      newState.uid = action.uid
      newState.userName = action.userName
      newState.image = action.image 
      return newState
    case ADMIN_LOGOUT:
      newState.id = action.id
      newState.email = ''
      newState.accessToken = ''
      newState.client = ''
      newState.uid = ''
      newState.userName = ''
      newState.image = ''
      return newState
    default:
      return state
  }
}


export default combineReducers({
  user,
  admin,
  theme
})