import React, { Component } from 'react';
import { Form, Button,Row,Col, Card} from 'react-bootstrap';
import '../App.css';
import * as api from '../api/api';
import * as actions from '../actions';
import { connect } from 'react-redux';
import ConfirmOtp from './ConfirmOtp';
import centalogo from '../assets/images/logo.png';

class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: '',
      FPerrorMsg:'',
      FPMessage:'',
      selectedOption: "",
      phone: '',
      sendingOption: [],
      successStatus: false,
      type: '',
      paramsType: '',
      mobileDisabled: false,
      emailDisabled: false,
      showError: false,

    }
  }

  componentDidMount() {
    let mobileDisabled, emailDisabled;
    let paramsType = this.props.match.params.type
    if((paramsType !== 'forgot') && (paramsType !== 'signin')) {
      mobileDisabled = true
      emailDisabled = true
    }
    this.setState({paramsType: paramsType, mobileDisabled: mobileDisabled, emailDisabled: emailDisabled})
    // Cache Clearing
    let cachesName = ['my-pwa-cache-v1']
    cachesName.map(cacheName => {
      return caches.delete(cacheName)
    })
    if ('serviceWorker' in navigator) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      })
    }
  }

  ForgotPassword = (event) => {
    if(event.target.id === 'phone'){
      if(this.state.email === '')
      {
        this.setState({[event.target.id] : event.target.value})
      }
      else{
        this.setState({'email' : "", [event.target.id]: event.target.value})
      } 
    }
    if(event.target.id === 'email'){
      if(this.state.phone === '')
      { 
        this.setState({[event.target.id] :event.target.value})
      }
      else{
        this.setState({'phone' : "", [event.target.id]: event.target.value})
      } 
    }
  }

  handleForgetPassword = (e) => {
    this.setState({FPMessage:'', FPerrorMsg: '', errorMsg: ''})
    const validEmail = (new RegExp(/^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-zA-Z0-9]+(\-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(\-[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,4}$/).test(this.state.email))
    let email = this.state.email
    let phone = this.state.phone
    e.preventDefault()
    this.setState({mobileDisabled: true, emailDisabled: true})
    if((email === '') && (phone === '')){
      return this.setState({FPerrorMsg: 'Email or Mobile Number is required',mobileDisabled: false, emailDisabled: false, showError: true})
    }
    if(email.length !== 0){
      if((validEmail === false)){
        return this.setState({FPerrorMsg: 'Please Enter valid Email ID',mobileDisabled: false, emailDisabled: false, showError: true})
      }
    }
    if(phone.length !== 0){
      if(!(phone.length === 10 || phone.length === 12)){
        return this.setState({FPerrorMsg: 'Please enter a valid mobile number',mobileDisabled: false, emailDisabled: false, showError: true})
      }
    }
    
    let forgotPassword = {}
    if(this.state.email){
      forgotPassword.email = this.state.email
      forgotPassword.type = 'email'
    } else if (this.state.phone){
      forgotPassword.phone = this.state.phone
      forgotPassword.type = 'phone'
    }

    api.handleForgotPassword(forgotPassword)
      .then((response) =>{
          if(response.status === 'success') {
            this.setState({
              FPMessage: '', FPerrorMsg: '',successStatus: true,selectedOption: "-1",type: forgotPassword.type
            })
          }
          else if (response.status === 'error'){
            this.setState({
              FPMessage: response.errors, FPerrorMsg: '',mobileDisabled: false, emailDisabled: false, showError: true
            })
          }
          else if (response.status === 'false'){
            this.setState({
              FPMessage: response.message, FPerrorMsg: '',mobileDisabled: false, emailDisabled: false, showError: true
            })
          }
      })
    .catch(error => this.setState({FPerrorMsg: 'Please check your internet or contact your admin',mobileDisabled: false, emailDisabled: false.valueOf, showError: true}))
  }

  changeSendingOption = event => {
    this.setState({
      selectedOption: event.target.value
    });
  }

  onConfirmOTPSuccess = () => {
    if (this.props.user.role === 'school_organization_admin') {
      this.props.history.push('/pwa/scl/admin/reset/password')
    }
    else {
      if(this.state.paramsType === 'forgot') {
        this.props.history.push('/pwa/reset/password')
      }
      else if (this.state.paramsType === 'signin'){
        this.props.history.push('/pwa/home')
      }
    }
  }

  render() {
    let paramsType = this.state.paramsType
      let formComponent = (
        <form>
        <Form.Group controlId="phone" className="float-wrapper last mb-0 pb-0">
          <Form.Label className="float-label text-mute">Mobile Number</Form.Label>
          <Form.Control className="float-input" type="number" value={this.state.phone} onChange={this.ForgotPassword} />
        </Form.Group>
        <p className="font-weight-bold mb-2" style={{ marginTop: '10px' }}>
          This option is available only for Indian Mobile numbers.
        </p>
        <p className="text-center text-muted font-weight-normal mb-2" style={{marginTop: '10px'}}>OR</p>
        <Form.Group controlId="email" className="float-wrapper last pb-0">
            <Form.Label className="float-label text-mute">Email</Form.Label>
            <Form.Control className="float-input" type="text" value={this.state.email} onChange={this.ForgotPassword} />
          </Form.Group>
          <Button className="btn-block signin-btn" type="submit" onClick={this.handleForgetPassword} block>SUBMIT</Button>
      </form>
      )
      
    let headingText, msgText;
    if(paramsType === 'forgot'){
      headingText = 'FORGET USERNAME/PASSWORD?'
      msgText = 'reset your password'
    }
    else if(paramsType === 'signin') {
      headingText = 'Sign In'
      msgText = 'sign in'
    }

    let displayComponent;
    if(this.state.successStatus) {
      displayComponent = (
        <ConfirmOtp email={this.state.email} phone={this.state.phone} type={this.state.type} confirmRedirect={this.onConfirmOTPSuccess} onHide={this.props.handleClose}/>
      )
    }
    else {
      displayComponent = (
        <Card>
           <div className="signin-top-container">
              <div className="h-50 w-100 mt-3 d-flex justify-content-center align-items-center">
                <img src={centalogo} style={{width: "70%"}} alt="CentaLogo "/>           
              </div>
              <div className="h-25 w-100 mt-3">
                <Row>
                  <Col className="default-font d-flex signin-top-txt">
                  <p className="mb-0">8,00,000 Teachers | 6500 locations | 68 countries</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="default-font d-flex signin-top-txt">  
                    <p> Certification, Learning, Careers, Community</p> 
                  </Col>
                </Row>  
              </div>
            </div> 
            <div className="p-4 p-sm-4">
              <h6 className="heading-txt mb-2">{headingText}</h6>
              <p className="forget-txt">Enter your email address/ mobile number and we will send you an OTP to {msgText}.</p>
              {formComponent}
            </div>
          {this.state.showError ? 
            <>
              <p className="alertTextRedesign mb-0">{this.state.FPerrorMsg}</p>
              <p className="alertTextRedesign mb-0">{this.state.FPMessage}</p>
              <p className="alertTextRedesign mb-0">{this.state.errorMsg}</p>
            </>
          : null}
          <div className="py-3 pb-5 px-4 px-sm-5">
            <div className="text-center default-font">
              Click here to <a href="/pwa/signin" className="forget-btn">Sign In</a>
            </div>
          </div>
        </Card>
      )
    }

    return(
      <div className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4">
        <div className="ui-bg-overlay bg-dark opacity-25"></div>
        <div className="authentication-inner py-3">
          {displayComponent}
        </div>
      </div>
    );
  }
}

function mapStateToProps({user}) {
  return {
    user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data)),
    adminLogin: data => dispatch(actions.adminLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)