// const api =
//   process.env.REACT_APP_CONTACTS_API_URL || 'http://localhost:3000';
// const api = '/api';
import camelCase from 'lodash/camelCase'
// import snakeCase from 'lodash/snakeCase'

//const api = 'http://localhost:3003/api';
const api = 'https://staging.centa.org/api';
//const api = 'https://centa.org/api';

function getHeader(accessToken,contentType='application/json') {
  const headers = new Headers()
  headers.append('Authorization', accessToken)
  if (contentType) {
     headers.append('Content-Type', contentType)
  }
  return headers;
}

export function signIn(values) {
  const obj = {}
  return fetch(`${api}/user/signin`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  })
    .then((resp) => {
      obj.accessToken = resp.headers.get('Access-Token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    })
}

export function signUp(values) {
  const obj = {}
  return fetch(`${api}/user/signup`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  })
    .then((resp) => {
      obj.accessToken = resp.headers.get('Access-token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    })
}
export function handleRecentResources(accessToken){
  var url = `${api}/user/learning_resource/resource/day`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
  .then( (response) => {
    return response
})

};
export function handleRecentOpportunities(accessToken){
  var url = `${api}/user/opportunites/render_latest_opportunity`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
  .then( (response) => {
    return response
})

};
export function getOpportunities(accessToken){
  var url = `${api}/opportunities`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
  .then( (response) => {
    return response
})
};

export function handleForgotPassword(values) {
    var url = `${api}/user/otp/forgot_password`
    return fetch(url, {
      method: 'POST', 
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    }).then(res => res.json())
    .then( (response) => {
      return response
    })
};

export function handleSearch(accessToken,search) {
  var url = `${api}/opportunities/search?query=${search}`
  return fetch(url, {
   method: 'GET', 
   headers: getHeader(accessToken),
}).then(res => res.json())
};

export function handleOpportunityType(accessToken){
  var url = `${api}/opportunity_types`
  return fetch(url, {
   method: 'GET', 
   headers: {
      "access-control-allow-origin" : "http://localhost:3000",
      "Content-type": "application/json; charset=UTF-8"
    }
}).then(res => res.json())

};

export function handleOpportunityTypeChange(id,accessToken){
  var url = `${api}/opportunities/search/type/${id}/Yes`
  return fetch(url, {
   method: 'GET', 
   headers: getHeader(accessToken),
}).then(res => res.json())
  .then( (response) => {
    return response
})

};

export function handleAppliedOpportunities(accessToken){
  var url = `${api}/user/opportunities`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function handleApplyOpportunity(apiInput,accessToken){
  var url = `${api}/opportunity/apply?id=${apiInput.opportunity_id}`
  return fetch(url, {
    method: 'PUT', 
    headers:getHeader(accessToken),
    body: JSON.stringify(apiInput),
}).then(res => res.json())
  .then( (response) => {
    return response
})
}

export function getLearningThemes(accessToken) {
  let url = `${api}/user/learning_resource/fetch_swot_tags`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => res.json())
    .then( (response) => {
      return response
  });
};

export function addUserSwotAssociations(accessToken, values) {
  let url = `${api}/user/learning_resource/user_swot_taxonomies`;
  return fetch(url, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values)
  }).then(res => {return res.json()});
}

export function removeUserSwotAssociation(accessToken, userAssociationId) {
  let url = `${api}/user/learning_resource/delete/user_swot_taxonomies/${userAssociationId}`;
  return fetch(url, {
    method: 'POST',
    headers: getHeader(accessToken),
  }).then(res => {return res.json()});
}


export function getAspects(accessToken,competencyId) {
  let url = `${api}/user/learning_resource/competency/aspects/${competencyId}`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => { return res.json()});
}

export function getResources(accessToken, aspectId) {
  let url = `${api}/user/learning_resource/resources/${aspectId}`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => { return res.json()});
}


export function getUserActivity(accessToken, resourceId) {
  let url = `${api}/learning_res_user_activity/${resourceId}`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => { return res.json()});
}

export function updateUserActivity(accessToken, values) {
  let url = `${api}/user/learning_resource/update_user_activity`;
  return fetch(url, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(res => {return res.json()});
}

export function getFutureMonths(accessToken) {
  let url = `${api}/future_months/12`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => { return res.json()});
}

export function getBookmarks(accessToken,targetMonth) {
  let url = `${api}/learning_resource/my_learning_path/bookmark/${targetMonth}`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => { return res.json()});
}

export function getArchives(accessToken) {
  let url = `${api}/learning_resource/my_learning_path/archive`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => { return res.json()});
}

export function getCountryList(accessToken){
  var url = `${api}/countries`
  return fetch(url, {
   method: 'GET', 
   headers:{
            'Authorization': accessToken
           },
}).then(res => res.json())
  .then( (response) => {
    return response
})
};

export function getUserDashboardDetails(accessToken){
  var url = `${api}/user/profile/data` 
  return fetch(url, {
   method: 'GET', 
   headers: getHeader(accessToken),
  }).then(res => res.json())
};

export function getAspectStandards(accessToken, aspectId){
  let url = `${api}/user/learning_resource/aspects/standards/${aspectId}`;
  return fetch(url, {
    method: 'GET',
    headers: getHeader(accessToken)
  }).then(res => { return res.json()});
}

export function submitUserReview(review,accessToken){
  var url = `${api}/user/learning_resource_activity/update_user_review`
  return fetch(url, {
   method: 'POST', 
   headers:getHeader(accessToken),
   body: JSON.stringify(review)
}).then(res => res.json())
  .then( (response) => {
    return response
})
};

export function getSampleQuiz(accessToken){
  var url = `${api}/questionnaires/type/4` 
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function getUserResponse(accessToken,id){
  var url = `${api}/questionnaires/${id}` 
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function submitResetPassword(data,accessToken){
  var url = `${api}/password/reset`
  return fetch(url, {
   method: 'POST', 
   headers:getHeader(accessToken),
   body: JSON.stringify(data)
}).then(res => res.json())
};

export function submitResetUserName(data,accessToken){
  var url = `${api}/username/reset`
  return fetch(url, {
   method: 'POST', 
   headers:getHeader(accessToken),
   body: JSON.stringify(data)
}).then(res => res.json())
};

export function submitOTP(data) {
  const obj = {}
  return fetch(`${api}/user/forgot_password_code_login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      obj.accessToken = resp.headers.get('Access-Token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    })
}

export function getReferralLink(accessToken){
  var url = `${api}/user/pwa/referral_link`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function getTQFormUserDetails(accessToken){
  var url = `${api}/user/fetchuserreg`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};


export function getUserOccupationList(accessToken){
  var url = `${api}/teaching_associations`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

/*API for TPO Registration*/
export function getTPOReferences(accessToken) {
  return fetch(`${api}/tpo_references`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}
export function getTeachingAssociations(accessToken) {
  return fetch(`${api}/teaching_associations`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getWorkingPositions(accessToken) {
  return fetch(`${api}/working_positions`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getTeachingAssociationOthers(accessToken) {
  return fetch(`${api}/teaching_association_others`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getTpoReferenceSub(reference_id) {
  return fetch(`${api}/tpo_reference_sub/${reference_id}`, {
    method: 'GET',
  }).then(resp => resp.json()) 
}

export function getuserForRegistration(accessToken) {
  return fetch(`${api}/user/fetchuserreg`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
  .then(result => toCamel(result))
}

export function saveTPoRegistrationLoggedin(accessToken, values) {
  return fetch(`${api}/tpo_certifications`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

export function getTPOLocation(accessToken, testCenterId){
  return fetch(`${api}/test_location/${testCenterId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getCountries(accessToken) {
  return fetch(`${api}/countries`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getStates(accessToken, country_id) {
  return fetch(`${api}/countries/${country_id}/states`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getPaymentModes(accessToken) {
  return fetch(`${api}/payment_mode_list`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTestCenters(accessToken) {
  return fetch(`${api}/test_centers`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getTPOTestTracks(accessToken, country_id) {
  return fetch(`${api}/tpo_test_track_list/${country_id}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTPOTimeSlot(accessToken, testTrackId, primary){
  return fetch(`${api}/get_test_track_time_slots/${testTrackId}/${primary}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTPOTrackFee(accessToken, testTrackId){
  return fetch(`${api}/tpo/track_fee/${testTrackId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTPOCountryFee(accessToken, countryId){
  return fetch(`${api}/get_tpo_fee/${countryId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTPODiscountPerTrack(accessToken, ceritfication_type, discount_code, amount,cert_part_id){
  return fetch(`${api}/calculate_tpo_discount?certification_type=${ceritfication_type}&discount_code=${discount_code}&amount=${amount}&cert_part_id=${cert_part_id}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
  .then(result => toCamel(result))
}

function toCamel(o) {
  let newO
  let origKey
  let newKey
  let value
  if (o instanceof Array) {
    newO = []
    for (origKey in o) {
      value = o[origKey]
      if (typeof value === 'object') {
        value = toCamel(value)
      }
      newO.push(value)
    }
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = camelCase(origKey)
        value = o[origKey]
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}

export function getPayments(accessToken) {
  return fetch(`${api}/payments`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function fetchRegDetails(accessToken, certification){
  return fetch(`${api}/reg_details/${certification}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function proceedPayment(accessToken, payment_id) {
  return fetch(`${api}/pwa_proceed_payment/${payment_id}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTQTestTracks(accessToken, certification_name){
  return fetch(`${api}/tq_test_tracks?certification_name=${certification_name}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
  .then(result => toCamel(result))
}

export function getTQTestDates(accessToken,test_center_id){
  return fetch(`${api}/tq_test_dates/${test_center_id}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
  .then(result => toCamel(result))
}

export function saveTqCertifications(accessToken, values) {
  return fetch(`${api}/tq_certifications`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

export function getTqTestCenters(accessToken) {
  return fetch(`${api}/tq_test_centers`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getFeeByCertificationType(certification_type) {
  return fetch(`${api}/get_fee_by_certification_type/${certification_type}`, {
    method: 'GET',
  }).then(resp => resp.json()) 
}

export function getTQDiscountPerTrack(accessToken, ceritfication_type, discount_code, amount, tq_test_track_id, selected_add_on){
  return fetch(`${api}/calculate_tq_discount?certification_type=${ceritfication_type}&discount_code=${discount_code}&amount=${amount}&tq_test_track_id=${tq_test_track_id}&global_type_id=${selected_add_on}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
  .then(result => toCamel(result))
}

export function getCertificationLevels(accessToken) {
  return fetch(`${api}/certification_level_list`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getMcTopics(accessToken) {
  return fetch(`${api}/mc_topics`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTestTracks(accessToken, type) {
  return fetch(`${api}/test_track_list/${type}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getFeeByLevel(accessToken, certification_level_id, certification_type_id) {
  return fetch(`${api}/get_fee_by_leavel/${certification_level_id}/${certification_type_id}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function calculateMCDiscount(accessToken, certification_level_id, certification_type_id, discount_code, mc_topic_count) {
  return fetch(`${api}/calculate_mc_discount/${certification_level_id}/${certification_type_id}/${discount_code}/${mc_topic_count}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getResponse(accessToken, url) {
  return fetch (`${api}/${url}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getResponseInCamelCase(accessToken, url) {
  return fetch(`${api}/${url}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
  .then(result => toCamel(result))
}


export function getTQAchievements(accessToken) {
  return fetch(`${api}/fetch/tq/reg/reports`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function miebAchievements(accessToken) {
  return fetch(`${api}/mieb_achievements`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function downloadMIEBReport(accessToken, mieb_cert_id, ){
  return fetch(`${api}/mieb/report/gen/${mieb_cert_id}`, {
    method: 'GET',
    headers: getHeader(accessToken),
   responseType: 'application/pdf',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/pdf" 
      if(contentType === contentTypeVal){
        return resp.blob();
      }else{
        return resp.blob();    
      }
   }) 
}

export function mcUserReportLink(accessToken) {
  return fetch(`${api}/mc_user_cert_report`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function fcUserReportLink(accessToken) {
  return fetch(`${api}/fc_user_cert_report`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function saveMicroCredentialsCertifications(accessToken, values) {
  return fetch(`${api}/micro_credentials_certifications`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
    }).then(resp => resp.json())
}

export function getMySubmissions(accessToken) {
  return fetch(`${api}/registrations/mysubmissions`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function fetchMCSubmissionDetails(accessToken, mcCertTopicId) {
  return fetch(`${api}/uploads/mc_cert_topic/${mcCertTopicId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function uploadCertArtifact(accessToken, formData) {
  return fetch(`${api}/uploads/upload/artifact`, {
    method: 'POST',
    headers: getHeader(accessToken, null),
      body: formData,
  }).then(resp => resp.json())
}

export function modifyUploadArtifact(accessToken, formData) {
  return fetch(`${api}/uploads/modify/artifact`, {
    method: 'POST',
    headers: getHeader(accessToken, null),
      body: formData,
  }).then(resp => resp.json())
}

export function fetchTQSubmissionDetails(accessToken, tqCertDetailId){
  return fetch(`${api}/uploads/tq_cert/${tqCertDetailId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function tpoAchievements(accessToken) {
  return fetch(`${api}/tpo_achievements`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getTPOReport(accessToken, rollno, year){
  return fetch(`${api}/reports/tpo_repots/${rollno}/${year}`, {
    method: 'GET',
    headers: getHeader(accessToken),
    responseType: 'application/octet-stream',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/octet-stream" 
      if(contentType === contentTypeVal){
        return resp.blob();
      }else{
        return resp.json();    
      }
   }) 
}

export function downloadReport(accessToken, downloadURL){
  return fetch(`${api}/${downloadURL}`, {
    method: 'GET',
    headers: getHeader(accessToken),
   responseType: 'application/pdf',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/pdf" 
      if(contentType === contentTypeVal){
        return resp.blob();
      } else {
        return resp.json();    
      }
   })
}

export function fetchTPORegStatus(accessToken){
  return fetch(`${api}/tpo/registration/check`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getCompetencies(accessToken){
  return fetch(`${api}/user/learning_resource/taxonomy/2`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTPOCountries(accessToken) {
  return fetch(`${api}/tpo_registration/countries`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getSurvey(accessToken){
  var url = `${api}/questionnaires/type/3` 
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function getRewardDetails(accessToken){
  var url = `${api}/user/activity_details` 
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function getAppVersion() {
  var url = `${api}/app/version`
  return fetch(url, {
    method: 'GET',
    headers: {
      "Content-type": "application/json"
    }
  }).then(res => res.json())
};

export function getRecentQuiz(accessToken) {
  var url = `${api}/questionnaire/specific_display`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function getBookMarksMonths(accessToken){
  var url = `${api}/user/bookmark/months`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function handleUserName(values) {
    var url = `${api}/user/pwd/reset/status`
    return fetch(url, {
      method: 'POST', 
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    }).then(res => res.json())
};

export function handleOtpSignIn(values) {
    const obj = {};
    var url = `${api}/user/confirm`
    return fetch(url, {
      method: 'POST', 
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    }).then((resp) => {
      obj.accessToken = resp.headers.get('Access-Token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    })
};

export function getTPOPartialDetails(accessToken,certification_participation_id){
  var url = `${api}/tpo_reg_details_update/${certification_participation_id}`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function updateTPORegistration(accessToken, values) {
  return fetch(`${api}/tpo_certification/update`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

export function getTPORegistrationStatus(accessToken) {
 return fetch(`${api}/tpo/reg/status`, {
   method: 'GET',
   headers: getHeader(accessToken),
 }).then(resp => resp.json())
 
}

export function userHallticketDetails(accessToken){
  return fetch(`${api}/hallticket/details`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function downloadHallticket(accessToken, hallTicketId){
  return fetch(`${api}/hallticket/gen/${hallTicketId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
   responseType: 'application/pdf',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/pdf" 
      if(contentType === contentTypeVal){
        return resp.blob();
      }else{
        return resp.blob();    
      }
  }) 
}

export function getTPOSolutionQuiz(accessToken){
  return fetch(`${api}/user/tpo_post_solution`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function tokenLogin(values) {
  const obj = {}
  return fetch(`${api}/user/token_login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  }).then((resp) => {
      obj.accessToken = resp.headers.get('Access-token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    })
}

// =====================================================================
// # Course Package API
// =====================================================================
export function getCoursePackage(accessToken, entityId){
  var url = `${api}/purchase_items/show/${entityId}/1`
  return fetch(url, {
   method: 'GET', 
   headers: getHeader(accessToken),
}).then(res => res.json())

};

export function getPackageModuleList(accessToken, packageId){
  var url = `${api}/purchase_items/purchase_item_and_parent_assosication/${packageId}`
  return fetch(url, {
   method: 'GET', 
  headers: getHeader(accessToken),
}).then(res => res.json())
  .then( (response) => {
    return response
})
};

export function handleEntititySave(accessToken, apiInput) {
  return fetch(`${api}/purchase_orders/create`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(apiInput),
  }).then(resp => resp.json())
}

export function getCartDetails(accessToken) {
  return fetch(`${api}/purchase_orders/display_orders`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function handlePurchasePay(accessToken, paymentId, apiInput) {
  return fetch(`${api}/purchase_orders/purchase_pay/${paymentId}`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(apiInput),
  }).then(resp => resp.json())
}

export function getPurchaseDiscount(accessToken, cartItemDetails) {
  return fetch(`${api}/purchase_orders/purchase_item_price`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(cartItemDetails),
  }).then(resp => resp.json()) 
}

export function downloadTPOInvoice(accessToken, paymentId){
  return fetch(`${api}/invoice/${paymentId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
   responseType: 'application/pdf',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/pdf" 
      if(contentType === contentTypeVal){
        return resp.blob();
      }else{
        return resp.blob();    
      }
   }) 
}

export function downloadAdminTQReport(accessToken, tqReportId){
  return fetch(`${api}/tpo/report/gen/${tqReportId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
   responseType: 'application/pdf',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/pdf"
      if(contentType == contentTypeVal){
        return resp.blob();
      }else{
        return resp.blob();    
      }
   })
}

export function getTQType(accessToken) {
  return fetch(`${api}/tq/types`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

// =====================================================================
// # Signup OTP
// =====================================================================

export function regenerateOTP(values) {
  return fetch(`${api}/user/regen_otp`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

// # New Opportunity API
// =====================================================================

export function handleNewOpportunityList(accessToken){
  var url = `${api}/opportunities/list/details/0`
  return fetch(url, {
    method: 'GET', 
    headers: getHeader(accessToken)
  }).then(res => res.json())
}
// Discourse api - start
export function forumSingleSignOn(accessToken, paramValue) {
  let url = `${api}/forum/singlesignon${paramValue}`;
  return fetch(url, {
    method: 'POST',
    headers: getHeader(accessToken),
  }).then(res => {return res.json()});
}
// Discourse api - end

// New Questionnaire API
export function handleQuestionnaireList(accessToken){
  var url = `${api}/fetch/by/sub/category`
  return fetch(url, {
    method: 'GET', 
    headers: getHeader(accessToken)
  }).then(res => res.json())
}

// API to Fetch Dashboard banners
export function getDashboardBannerDetails(accessToken){
  return fetch(`${api}/user/dashboard_banner_details`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

//API To fetch Webinar
export function getWebinarDetails(accessToken){
  return fetch(`${api}/list/of/seminars`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}
 //API to fetch Testimonials
 export function getTestimonials(accessToken){
  return fetch(`${api}/testimonials`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

//API to Fetch Affliated Programme - Entity List 
export function getPurchaseEntityList(accessToken, type){
  return fetch(`${api}/purchase_entities/entity_list?entity_type=${type}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getBadgesList(accessToken){
  return fetch(`${api}/fetch/badges?version="8.01"`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getIndividualCompetency(accessToken, competencyId){
  return fetch(`${api}/user/learning_resource/taxonomy/details/${competencyId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getLrIndividualPost(postId,accessToken) {
  return fetch(`${api}/lr/fetch/blog/post/${postId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

//Payment Mode API for TQ Form based on country
export function getCountryPaymentModes(accessToken, countryId) {
  return fetch(`${api}/country/based/payment_mode_list/${countryId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

//API to check TQ Registration restriction
export function getTQRegistrationCheck(accessToken, tqTestTrackId) {
  return fetch(`${api}/tq/check/track/eligibility/${tqTestTrackId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function fetchTQRegStatus(accessToken){
  return fetch(`${api}/tq/check/reg/eligibility`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function getTQRegistrationDetails(accessToken, certification_participation_id){
  return fetch(`${api}/fetch/tq/registration/${certification_participation_id}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function updateTQRegistration(accessToken,tqCertId, values) {
  return fetch(`${api}/tq/certification/update/${tqCertId}`, {
    method: 'PUT',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

// To Fetch the TQ fee text
export function getTQFeeText(accessToken){
  return fetch(`${api}/fetch/tq/fee/message`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

//To Fetch User Details for Opportunity
export function getUserDetails(accessToken){
  return fetch(`${api}/fetch/user/details`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json()) 
}

export function olderTQAchievements(accessToken) {
  return fetch(`${api}/tq_achievements`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function saveMethod(accessToken, url, values) {
  return fetch(`${api}/${url}`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(res => {return res.json()});
}

export function saveFormMethod(accessToken, url, values) {
  return fetch(`${api}/${url}`, {
    method: 'POST',
    headers: getHeader(accessToken, null),
    body: values,
  }).then(res => {return res.json()});
}

export function updateFormMethod(accessToken, url, values) {
  return fetch(`${api}/${url}`, {
    method: 'PUT',
    headers: getHeader(accessToken, null),
    body: values,
  }).then(res => { return res.json() });
}

export function handleDelete(accessToken, url) {
  return fetch(`${api}/${url}`, {
   method: 'DELETE', 
  headers: getHeader(accessToken),
}).then(res => res.json())
};

export function handleUpdate(accessToken, url, data){
  return fetch(`${api}/${url}`, {
   method: 'PATCH', 
  headers: getHeader(accessToken),
  body: JSON.stringify(data)
}).then(res => res.json())
};

export function exportData(accessToken, downloadURL){
  return fetch(`${api}/${downloadURL}`, {
    method: 'GET',
    headers: getHeader(accessToken),
    responseType: 'blob',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8" 
      if(contentType === contentTypeVal){
        return resp.blob();
      } else {
        return resp.json();    
      }
   })
}

export function adminSignIn(values) {
  const obj = {}
  return fetch(`${api}/administrator/signin`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  })
    .then((resp) => {
      obj.accessToken = resp.headers.get('Access-Token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    })
}

export function putMethodRequest(accessToken, url, values) {
  return fetch(`${api}/${url}`, {
    method: 'PUT',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

export function getOpportunityCategories(accessToken) {

  return fetch(`${api}/categories/opportunities`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
}

export function getOpportunity(accessToken, opportunityId) {
  return fetch(`${api}/opportunities/${opportunityId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
  }).then(resp => resp.json())
    .then(result => toCamel(result))
}

export function postValuesApi(accessToken, url, values) {
  return fetch(`${api}/${url}`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

export function putValuesApi(accessToken, url, values) {
  return fetch(`${api}/${url}`, {
    method: 'PUT',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  }).then(resp => resp.json())
}

export function postValuesApiWithHeaders(accessToken, url, values) {
  const obj = {}
  return fetch(`${api}/${url}`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values),
  })
  .then((resp) => {
    obj.accessToken = resp.headers.get('Access-Token')
    return resp.json()
  })
  .then((response) => {
    response.headers = {}
    response.headers.accessToken = obj.accessToken
    return response
  })
}

export function outsideSignIn(url, values) {
  const obj = {}
  return fetch(`${api}/${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  })
    .then((resp) => {
      obj.accessToken = resp.headers.get('Access-Token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    })
}

export function handleFCMDelivery(values) {
  return fetch(`{FCM_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
               'Authorization': 'key={API_SERVER_KEY}',
               'Access-Control-Allow-Origin': '*' },
    body: JSON.stringify(values),
  })
    .then((resp) => {
      console.log(resp);
    })
}

export function postValuesApiWithHeadersToCamel(accessToken, url) {
  const obj = {}
  return fetch(`${api}/${url}`, {
    method: 'POST',
    headers: getHeader(accessToken)
  })
  .then((resp) => {
    obj.accessToken = resp.headers.get('Access-Token')
    return resp.json()
  })
  .then((response) => {
    response.headers = {}
    response.headers.accessToken = obj.accessToken
    return response
  }).then(result => {
    return toCamel(result)
  })
}

export function postValuesWithHeaders(accessToken, url, values) {
  const obj = {}
  return fetch(`${api}/${url}`, {
    method: 'POST',
    headers: getHeader(accessToken),
    body: JSON.stringify(values)
  })
    .then((resp) => {
      obj.accessToken = resp.headers.get('Access-Token')
      return resp.json()
    })
    .then((response) => {
      response.headers = {}
      response.headers.accessToken = obj.accessToken
      return response
    }).then(result => {
      return toCamel(result)
    })
}

export function downloadAdminSeminarReport(accessToken, seminarUserId){
  return fetch(`${api}/download/seminar/participation/certificate?seminar_user_id=${seminarUserId}`, {
    method: 'GET',
    headers: getHeader(accessToken),
   responseType: 'application/pdf',
  }).then((resp) => {
      let contentType = (resp.headers.get('content-type'))
      let contentTypeVal = "application/pdf"
      if(contentType == contentTypeVal){
        return resp.blob();
      }else{
        return resp.json();    
      }
   })
}

export function uploadSeminarImportExcel(accessToken, formData) {
  return fetch(`${api}/admin/seminar/users/upload`, {
    method: 'POST',
    headers: getHeader(accessToken, null),
      body: formData,
  }).then(resp => resp.json())
}



