import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Sidenav from '../../../vendor/libs/sidenav'
import layoutHelpers from '../helpers'
import logo_img from '../../../assets/images/c.png'

class AdminSideNav extends Component {
  layoutSidenavClasses() {
    let bg = this.props.sidenavBg

    if (this.props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      this.props.orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  isMenuActive(url) {
    return this.props.location.pathname.indexOf(url) === 0
  }

  isMenuOpen(url) {
    return this.props.location.pathname.indexOf(url) === 0 && this.props.orientation !== 'horizontal'
  }

  render() {
    let menuItem, linkTo
    // Start of Customer Service Associate
    if(this.props.user.role === 'customer_service_associate') {
      menuItem = (
        <React.Fragment>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/modulecheck">Purchase Entity Details</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/purchase_user_details">Purchase User details</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/tq/registration/details">TQ Registration Details</Sidenav.RouterLink>
          {/* <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/opportunities">Opportunities</Sidenav.RouterLink> */}
          <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/admin/logout">Logout</Sidenav.RouterLink>
        </React.Fragment>
      )
      linkTo = "/pwa/admin/modulecheck"
    } else if (this.props.user.role === 'functional_lead') {
      menuItem = (
        <React.Fragment>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/view_reg_number">View TPO/ TQ Registrations</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/view_discount_code">Search Specific Coupon</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/configure_discount_code">Configure Discount Code</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/reg/details/functional_lead/partialfullregdata">TQ Registration Export</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/admin/logout">Logout</Sidenav.RouterLink>
        </React.Fragment>
      )
      linkTo = "/pwa/admin/view_reg_number"
    } else if (this.props.user.role === 'assessments_associate') {
      menuItem = (
        <React.Fragment>
          <Sidenav.RouterLink icon="ion ion-ios-book" to="/pwa/admin/reg/details/assessments_associate/EwRegHome">Education World Registrations</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-cloud-upload-alt" to="/pwa/assessments_associate/tqscores/home">TQ Report Scores Upload</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-upload" to="/pwa/assessments_associate/tqregistration/home">TQ Registrations Upload</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/reg/details/assessments_associate/regdata">TQ Registration Export</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-cloud-download-alt" to="/pwa/admin/user/contributions">User Contributions</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/admin/logout">Logout</Sidenav.RouterLink>
        </React.Fragment>
      )
      linkTo = "/pwa/admin/reg/details/assessments_associate/regdata"
    } else if ( this.props.user.role === 'school_organization_admin'){

      menuItem = (
        <React.Fragment>
          {(!this.props.user || !this.props.user.accessToken) &&
            <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/signin">Sign in</Sidenav.RouterLink>}
          <Sidenav.RouterLink icon="fas fa-home" to="/pwa/scl/admin/home">Home</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-id-badge" to="/pwa/scl/org/admin/profile">School Profile</Sidenav.RouterLink>
          {/*<Sidenav.RouterLink icon="fas fa-certificate" to="/pwa/school_org_admin/walkin/allocation/walk_in_tq">Walk-in TQ Allocation</Sidenav.RouterLink>*/}
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/post/home">My Posts</Sidenav.RouterLink>
          {/*<Sidenav.RouterLink icon="oi oi-book" to="/pwa/scl/admin/walkintq">WALK-IN TQ</Sidenav.RouterLink>*/}
          <Sidenav.RouterLink icon="oi oi-book" to="/pwa/scl/admin/vouchers">Vouchers</Sidenav.RouterLink>
          {(this.props.user && this.props.user.accessToken) && <Sidenav.RouterLink icon="oi oi-list-rich" to="/pwa/scl/admin/purchases">List of Purchases</Sidenav.RouterLink>}
          {(this.props.user && this.props.user.accessToken) && <Sidenav.RouterLink icon="oi oi-list-rich" to="/pwa/scl/admin/vouchers/list">List of Vouchers</Sidenav.RouterLink>}
          {/*<Sidenav.RouterLink icon="fas fa-tasks" to="/pwa/allocations">List of Allocations</Sidenav.RouterLink>*/}
          {/*(this.props.user && this.props.user.accessToken) && <Sidenav.RouterLink icon="fas fa-tasks" to="/pwa/licenses">List of Licenses</Sidenav.RouterLink>*/}
          {(this.props.user && this.props.user.accessToken) && <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/logout">Logout</Sidenav.RouterLink>}
        </React.Fragment>
      )
      linkTo = "/pwa/scl/admin/home"
    }
    else if(this.props.user.role === 'opportunity_admin'){
      menuItem = (
        <React.Fragment>
          <Sidenav.RouterLink icon="ion ion-md-document" to="/pwa/admin/post/home">Post Opportunities</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="id-block fas fa-mail-bulk" to="/pwa/admin/reg/details/opportunity_admin/opportunity">Export - Opportunity Apply  Details</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/logout">Logout</Sidenav.RouterLink>
        </React.Fragment>
      )
      linkTo = "/pwa/admin/post/create"
    }

    else if(this.props.user.role === 'admin'){
      menuItem = (
        <React.Fragment>
          <Sidenav.RouterLink icon="ion ion-md-browsers" to="/pwa/admin/featured/items/configuration/home/1">Featured Items Configuration</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-ios-book" to="/pwa/admin/list/purchase/entities">Affiliated Programs and Master Classes</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-ios-book" to="/pwa/admin/list/learning/resources">Learning Resources</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="d-block oi oi-data-transfer-download" to="/pwa/admin/reg/details/admin/kidoRegExport">Kido Registration Export</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="d-block oi oi-data-transfer-download" to="/pwa/admin/reg/details/admin/mcRegExport">MC Registrations</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-users" to="/pwa/admin/list/user_details">User Details</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-user-edit" to="/pwa/admin/list/update_user_details">Update User Details</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-user-edit" to="/pwa/admin/vouchers/home">Vouchers</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-browsers" to="/pwa/admin/reward/configration/home">Reward Configuration</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-notifications" to="/pwa/admin/notifications/home">Notifications History</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="ion ion-md-notifications" to="/pwa/admin/user/notification/home">User Notifications</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="far fa-list-alt" to="/pwa/admin/purchase_order/participation/home">Purchase Order Participation Upload</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="far fa-calendar-check" to="/pwa/admin/tq/time/slots">TQ Time Slots</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="far fa-calendar-check" to="/pwa/admin/tq_registration">TQ Registration</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="d-block oi oi-data-transfer-download" to="/pwa/admin/export/admin/haryana_registration">Haryana Registration Export</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-chalkboard" to="/pwa/admin/webinar_list">Webinar Section</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-book-open" to="/pwa/admin/course/entity/home">Course Package</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="d-block oi oi-data-transfer-download" to="/pwa/admin/reg/details/admin/purchaseReminder">Product Purchase Reminder</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-id-badge" to="/pwa/admin/purchase_purchase/home">Cart/ License Details</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-id-badge" to="/pwa/admin/sme/home">SME Contractors</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-code-branch" to="/pwa/admin/pwa_app_version">Change App Version</Sidenav.RouterLink>
          <Sidenav.RouterLink icon="fas fa-power-off" to="/pwa/logout">Logout</Sidenav.RouterLink>
        </React.Fragment>
      )
      linkTo = "/pwa/admin/featured/items/configuration/home/1"
    }

    // End
    return (
      <Sidenav orientation={this.props.orientation} className={this.layoutSidenavClasses()}>

        {/* Brand demo (see src/demo.css) */}
        {this.props.orientation !== 'horizontal' && (
          <React.Fragment>
            <div className="app-brand demo">
              <span className="app-brand-logo demo">
                <img src={logo_img} alt="logo.png" style={{height: '25px'}}/>
              </span>
              <Link to={linkTo} className="app-brand-text demo sidenav-text font-weight-normal ml-2">MyCENTA</Link>
              <a href="#toggle" className="layout-sidenav-toggle sidenav-link text-large ml-auto" onClick={this.toggleSidenav}>
                <i className="ion ion-md-menu align-middle"></i>
              </a>
            </div>
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}

        {/* Links */}
        <div className={`sidenav-inner ${this.props.orientation !== 'horizontal' ? 'py-1' : ''}`}>

          {/* MyCENTA Admin Menu */}
          {menuItem}
        </div>
      </Sidenav>
    )
  }
}

AdminSideNav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

AdminSideNav.defaultProps = {
  orientation: 'vertical'
}

export default connect(store => ({
  sidenavBg: store.theme.sidenavBg
}))(AdminSideNav)
