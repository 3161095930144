import React, { Component } from 'react';
import { Form, Row, Col, Button, Card} from 'react-bootstrap';
import { connect } from 'react-redux';
import '../App.css';
import * as api from '../api/api';
import * as actions from '../actions';
import centalogo from '../assets/images/logo.png';

class ConfirmOtp extends Component {
  constructor(props){
    super(props);
    this.state={
     confirmOtp: '',
     confirmMsg: '',
     buttonDisabled: false,
    }
  }

  componentDidMount() {
  }

  changeField = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  submitOTP = (e) => {
    e.preventDefault();
    this.setState({confirmMsg: '', buttonDisabled: false})
    if(this.state.confirmOtp === '') {
      return this.setState({confirmMsg: 'Please enter your OTP', buttonDisabled: false})
    }
    const obj = {};
    let data = {};
    data.forgot_password_code = this.state.confirmOtp;
    data.email = this.props.email;
    data.phone = this.props.phone;
    data.type = this.props.type;
    api.submitOTP(data)
    .then((response) => {
      if(response.status === 'success'){
          obj.accessToken = response.headers.accessToken            
          obj.userName = response.data.user_name
          obj.role = response.data.role
          obj.id = response.data.id
          let currentDate = new Date()
          obj.otherRoles = response.data.other_roles
          // Getting previous hour from current date and hour
          let prevHour = new Date(currentDate.getTime() - (1000 * 60 * 60));
          obj.pdHoursExpTime = prevHour
          this.props.userLogin(obj)
          this.props.confirmRedirect();
      }
      else if(response.status === 'error'){
        this.setState({confirmMsg: response.errors,buttonDisabled: false})
      }
    })
    .catch((error) => this.setState({confirmMsg: 'Error in Confirm OTP,Please check the internet else Please Contact the Admin',buttonDisabled: false}))
  }

  render() {
    return (
      <Card>
        <div className="signin-top-container">
          <div className="h-50 w-100 mt-3 d-flex justify-content-center align-items-center">
           <img src={centalogo} style={{width: "70%"}} alt="CentaLogo "/>           
         </div>
         <div className="h-25 w-100 mt-3">
           <Row>
             <Col className="default-font d-flex signin-top-txt">
               <p className="mb-0">8,00,000 Teachers | 6500 locations | 68 countries</p>
             </Col>
           </Row>
           <Row>
             <Col className="default-font d-flex signin-top-txt">  
               <p> Certification, Learning, Careers, Community</p> 
             </Col>
           </Row>  
         </div>
       </div>
          {/* <div className="forget-top-container">
            <div className="d-flex justify-content-center align-items-center position-relative" style={{top: "15vh"}}>
              <img src={centalogo} style={{width: "40%"}} alt="CentaLogo "/>
            </div>
         </div> */}
         <div className="p-4 p-sm-4">
           <h5 className="heading-txt mb-2">Please enter the OTP received</h5>
           <form>
              <Form.Group controlId="confirmOtp" className="float-wrapper last mb-0 pb-0">
                <Form.Control className="float-input" type="number" placeholder="Confirm OTP" value={this.state.confirmOtp} onChange={this.changeField} />
              </Form.Group>
              <Button className="btn-block signin-btn mt-4" onClick={this.submitOTP} disabled={this.state.buttonDisabled} type="submit">
                <b>SUBMIT</b>
              </Button>
           </form>
          </div>
        {this.state.confirmMsg ? <div className="alertTextRedesign">{this.state.confirmMsg}</div> : null}
        <div className="py-3 px-4 px-sm-5 pb-4">
          <div className="text-center default-font signin-bottom-btn">
            Click here to <a href="/pwa/signin">Sign In</a>
          </div>
        </div>
      </Card>
    );
  }
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data)),
    adminLogin: data => dispatch(actions.adminLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOtp);