import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import * as api from '../../api/api';
import * as actions from '../../actions';

class GoogleOneTapSignin extends Component {

  constructor(props){
    super(props);
    this.state={
      errorMsg: ''
    }
  }

  componentDidMount() {
    if(!this.props.user.accessToken && window.google) {
      window.google.accounts.id.initialize({
        client_id: '670962447874-hkbkfnjc64eldlmajjbs9vdi0tga2sci.apps.googleusercontent.com',
        callback: data => this.handleOnetapResponse(data)
      });
      window.google.accounts.id.prompt()
    }
  }

  handleOnetapResponse = (response) => {
    var JWT = response.credential
    let oneTapSigninURL = 'user/google/onetap'
    const obj = {}
    let apiInput = {}
    apiInput.token = JWT
    api.outsideSignIn(oneTapSigninURL, apiInput)
    .then((response) => {
      if(response.status === 'success'){
        obj.accessToken = response.headers.accessToken            
        obj.userName = response.data.user_name
        obj.role = response.data.role
        obj.id = response.data.id
        obj.otherRoles = response.data.other_roles
        this.props.userLogin(obj)
        this.props.handleOneTap()
      } else if(response.status === 'error') {
        this.setState({errorMsg: response.message})
      } else if(response.status === 'failure') {
        this.setState({errorMsg: response.message})
      }
    })
    .catch(error => this.setState({errorMsg: 'Check your internet or Contact your admin'})
    );
  }

  render() {
    return (
      <React.Fragment>
        <p className="alertTextRedesign">{this.state.errorMsg}</p>
      </React.Fragment>
    );
  }
}


function mapStateToProps({user}) {
  return {
    user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogin: data => dispatch(actions.userLogin(data)),
    adminLogin: data => dispatch(actions.adminLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleOneTapSignin)