import React, { Component } from 'react';
import { Form, Container,Row,Col,Button, Card, ListGroup, CardGroup, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import SignIn from './views/SignIn'
import SignUp from './views/SignUp'
import ListMenu from './views/ListMenu'
import HomePage from './views/HomePage'
import Registration from './views/Registration'
import Logout from './views/Logout'
import Main from './layout/Main'
import PrivateRoute from './helpers/PrivateRoute'
import ConfirmOtp from './views/ConfirmOtp'
import UpdateRequired from './layout/UpdateRequired'
import PasswordlessTokenLogin from './views/PasswordlessTokenLogin'
import ForgotPassword from './views/ForgotPassword'
import { updateThemeSettings } from './store/actions/themeActions'
import themeSettings from './vendor/libs/theme-settings'
import Router from './shared/Router'
import './App.scss'
import * as conversion from './views/push_notification/urlB64ToUint8Array'


class App extends Component {
  constructor(props) {
    super(props)

    // Update store on theme settings change
    themeSettings._themeSettings.settings.onSettingsChange = () =>
      this.props.updateThemeSettings()
  }

  componentDidMount() {
    // Push Notification Subscription Method
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(function(reg) {
          reg.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: conversion.urlB64ToUint8Array(process.env.REACT_APP_SERVER_KEY)
          }).then(function(sub) {
            let endpointURL = sub.endpoint;
            let subscriptionObj = localStorage.getItem('subscription')
            if(!subscriptionObj || subscriptionObj.endpoint !== endpointURL){
              localStorage.setItem('subscription', JSON.stringify(sub))
              localStorage.setItem('subscriptionSave', false)
            }
          }).catch(function(e) {
            if (Notification.permission === 'denied') {
              console.warn('Permission for notifications was denied');
            } else if (Notification.permission === 'default') {
              Notification.requestPermission()
            } else { 
              console.error('Unable to subscribe to push', e);
            }
          })
      })
    }
  }


  // componentDidMount() {  
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.ready.then(function(reg) {
  //         console.log(reg.pushManager)
  //         console.log("app js before subscribe v1")
  //         reg.pushManager.subscribe({
  //             userVisibleOnly: true,
  //             applicationServerKey: conversion.urlB64ToUint8Array(process.env.REACT_APP_SERVER_KEY)
  //         }).then(function(sub) {
  //           console.log(JSON.stringify(sub))
  //           let endpointURL = sub.endpoint;
  //           let subObj = localStorage.getItem('subscriptionObj')
  //           console.log(subObj)
  //           if(!subObj || subObj.endpoint !== endpointURL){
  //            localStorage.setItem('subscriptionObj', sub)
  //           }
  //         }).catch(function(e) {
  //           console.log(Notification.permission)
  //           if (Notification.permission === 'denied') {
  //             console.warn('Permission for notifications was denied');
  //           } else if (Notification.permission === 'default') {
  //             console.log('Inside Permission Req')
  //             Notification.requestPermission()
  //           } else { 
  //             console.error('Unable to subscribe to push', e);
  //           }
  //         })
  //     })
  //   }

  //     //end
  // }

  render() {
    return <Router />
  }
}

export default connect(null, { updateThemeSettings })(App)


// const App = () => (
//   <div>
//     <Switch>
//       <PrivateRoute path="/pwa/app" component={Main} />
//       <Route exact path="/" component={SignIn} />
//       <Route exact path="/pwa/signup" component={SignUp} />
//       <Route exact path="/pwa/listmenu" component={ListMenu} />
//       <Route exact path="/pwa/homepage" component={HomePage} />
//       <Route exact path="/pwa/registration" component={Registration} />
//       <Route exact path="/pwa/logout" component={Logout} />
//       <Route exact path="/pwa/confirmotp" component={ConfirmOtp} />
//       <Route exact path="/pwa/updaterequired" component={UpdateRequired} />
//       <Route exact path="/pwa/tokenlogin" component={PasswordlessTokenLogin} />
//       <Route exact path="/pwa/forgot_password" component={ForgotPassword} />
//       <Route exact path='/pwa/' render={() => ( 
//           <Redirect
//             to='/pwa/app'
//           />
//         )} />
//     </Switch>
//   </div>
// )

// export default App;