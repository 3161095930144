/* eslint-disable no-shadow */
import React, {Component} from 'react';

import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

class PrivateRoute extends Component {
  render() {
    //console.log("this.props.user.userName = " + this.props.user.userName)
    const { component, ...rest } = this.props

    return (
      <Route {...rest} render={routeProps => (
        this.props.user.userName ? 
        renderMergedProps(this.props.component, routeProps, rest) : (
        <Redirect to={{
        pathname: '/',
        state: { from: routeProps.location },
        }} />
      ))} />
    )
  }
}

function mapStateToProps({user, admin}) {
  return {
    user,
    admin
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)